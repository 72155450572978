import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationTypeEnum } from '@shared/enum/notification-type.enum';

@Injectable({ providedIn: 'root' })
export class HandleNotificationClickService {
  constructor(private router: Router) {}
  handleNotificationClick(notification) {
    if (
      notification.data.type === NotificationTypeEnum.NEW_ORDER as number ||
      notification.data.type === NotificationTypeEnum.CANCEL_ORDER as number||
      notification.data.type === NotificationTypeEnum.NOTIFY_WAITER_TO_UPDATE_ORDER  as number||
      notification.data.type === NotificationTypeEnum.NOTIFY_WAITER_TO_PAY_CASH as number
    ) {
      this.router.navigate(['orders/', notification.data.order_id]);
    } else if (
      notification.data.type === NotificationTypeEnum.UNLOCK_RESTAURANT_TABLE as number ||
      notification.data.type === NotificationTypeEnum.NOTIFY_WAITER as number
    ) {
      this.router.navigate(['tables/', notification.data.table_id]);
    }
  }
}
