<div
  class="p-0 m-3 mx-auto tw-size-48 tw-rounded-xl border-1 bg-white"
  [ngClass]="{
    'secondary-background-color': orderStatus === 1,
    'main-background-color': orderStatus === 4 || orderStatus === 2 || orderStatus === 3 || orderStatus === 5 || orderStatus === 8,
    'strong-orange-background-color': orderStatus === 9 || tableStatus === 2 || orderStatus === 7,
    'mauve-background-color': tableStatus === 4,
    'green-background-color': orderStatus === 6,
    'strong-orange-border-color': tableStatus === 3
  }">
  <div
    class="d-flex justify-content-center justify-align-center tw-h-8 tw-w-8  glass-background"
    [ngClass]="{
      'gray-background-color': tableStatus === 1,
      'bg-lock': tableStatus === 3,
      'tw-rounded-tl-xl': !language.isArabic,
      'tw-rounded-br-lg': !language.isArabic,
      'tw-rounded-tr-xl': language.isArabic,
      'tw-rounded-bl-lg': language.isArabic,
    }">
    <p class="p-0 m-auto tw-text-base tw-font-medium text-white">{{ tableNumber }}</p>
  </div>
  <div class="w-100 tw-h-4/5 p-2 d-flex flex-column justify-content-between m-auto">
    <img *ngIf="orderStatus === 2 || orderStatus === 7" class="tw-size-10 m-auto" src="assets/images/tableimage.png" alt="" />
    <img *ngIf="tableStatus === 1" class="tw-size-10 m-auto" src="assets/images/emptyTable.png" alt="" />
    <img *ngIf="orderStatus === 4" class="tw-size-10 m-auto" src="assets/images/preparingOrder.png" alt="" />
    <img
      *ngIf="orderStatus === 3 || orderStatus === 5 || tableStatus === 2"
      class="tw-size-10 m-auto"
      src="assets/images/accptedOrder.png"
      alt="" />
    <img *ngIf="tableStatus === 4" class="tw-size-10 m-auto" src="assets/images/ReservedTable.png" alt="" />
    <img *ngIf="tableStatus === 3" class="tw-size-10 m-auto" src="assets/images/lockTable.svg" alt="" />
    <img
      *ngIf="orderStatus === 6 || orderStatus === 9 || orderStatus === 8"
      class="tw-size-10 m-auto"
      src="assets/images/orderPaid.png"
      alt="" />
    <p
      class="text-white tw-text-xs text-center mt-1"
      [ngClass]="{ 'gray-color': tableStatus === 1, 'strong-orange-color': tableStatus === 3 }">
      {{ time }}
    </p>
    <p
      class="tw-text-sm text-center text-white"
      [ngClass]="{ 'gray-color': tableStatus === 1, 'strong-orange-color': tableStatus === 3 }">
      <span>{{ paidAmount | dividePrice }} {{ currency }}</span>
      / {{ totalAmount | dividePrice }}
      {{ currency }}
    </p>
    <div class="mt-2">
      <div
        class="rounded-5 text-center py-1 mt-1 glass-background"
        [ngClass]="{ 'gray-background-color': tableStatus === 1, 'bg-lock': tableStatus === 3 }">
        <p class="text-white tw-text-xs">{{ title | formatType }}</p>
      </div>
    </div>
  </div>
</div>
