import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'datePipe',
    pure: false,
})
export class DateFormatPipe implements PipeTransform {
    transform(value: string): string {
        return moment.utc(value).format('D MMMM,yyyy');
    }
}
