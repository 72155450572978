import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbComponentStatus } from '@nebular/theme';
import { FeesEnum } from '@shared/enum/fees.enum';
import { PermissionsEnum } from '@shared/enum/permissions.enum';
import { DialogData } from '@shared/models/dialog-data.model';
import { EnumModel } from '@shared/models/enum.model';
import { FeesSettingModel } from '@shared/models/feesSetting.model';
import { HttpErrorModel } from '@shared/models/http-error.model';
import { SettingsModel } from '@shared/models/settings.model';
import { LanguageService } from '@shared/services/language.service';
import { SettingService } from '@shared/services/setting/setting.service';
import { ToastService } from '@shared/services/toast.service';
import { MethodsUtils } from '@shared/utils/methods.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs';

@Component({
  selector: 'app-payment-gateway-settings-dialog',
  templateUrl: 'fees-settings-dialog.component.html',
  styleUrls: ['fees-settings-dialog.component.scss'],
})
export class FeesSettingsDialogComponent implements OnInit{
  feesTypeEnum: EnumModel[];
  permissionUpdate: string;
  permissionStore: string;
  submitted = false;
  feesForm: UntypedFormGroup;
  settings: SettingsModel | null = null;
  feesSettings: FeesSettingModel[] | null = null;
  httpError: HttpErrorModel | null = null;
  formData = new FormData();
  isAddFeesSettings: boolean = false;
  isEditFeesSettings: boolean = false;
  restaurantId: string;
  branchId: string;
  isPercent(index: number): boolean {
    return this.feesArray.at(index).get('type')?.value == 1;
  }
  
  public FeesEnum = FeesEnum;
  constructor(
    private methodUtils: MethodsUtils,
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private toastService: ToastService,
    private settingsService: SettingService,
    public language: LanguageService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<FeesSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.feesTypeEnum = this.methodUtils.getStatuses(FeesEnum);
    this.branchId = data.branchId;
    this.restaurantId = data.restaurantId;
    this.permissionUpdate = PermissionsEnum.UPDATE_BRANCH_SETTING;
    this.permissionStore = PermissionsEnum.STORE_BRANCH_SETTINGS;
    this.feesForm = this.fb.group({
      feesArray: this.fb.array([]) // Using FormArray for multiple fees
    });
  }

   // Getter for FormArray
   get feesArray(): UntypedFormArray {
    return this.feesForm.get('feesArray') as UntypedFormArray;
  }

  addFee(fee?: FeesSettingModel): void {
    const feeGroup = this.fb.group({
      title: [fee?.title || null, [Validators.required]],
      type: [fee?.type || FeesEnum.FIXED as number, [Validators.required]],
      amount: [
        fee?.type == 1 ? fee.amount * 100 : fee?.amount || null,
        [Validators.required]
      ],
    });
  
    // Add value change listener for 'type'
    feeGroup.get('type')?.valueChanges.subscribe((type) => {
      const amountControl = feeGroup.get('amount');
      if (type == 1) {
        // Handle conversion if necessary (for percent display)
        const currentValue = amountControl?.value || 0;
        amountControl.setValue(currentValue / 100, { emitEvent: false });
      } else {
        // Reset amount for fixed type (optional behavior)
        amountControl.setValue(amountControl.value, { emitEvent: false });
      }
      // Trigger UI change detection
      this.cdr.detectChanges();
    });
  
    this.feesArray.push(feeGroup);
    this.cdr.detectChanges();
  }

  removeFee(index: number): void {
    this.feesArray.removeAt(index);
  }

  get f() {
    return this.feesForm.controls;
  }

initFeesSettings() {
    if (this.feesSettings && Array.isArray(this.feesSettings)) {
      this.feesSettings.forEach(fee => this.addFee(fee));
    }
  }

  ngOnInit() {
    this.getFeesSettings();
  }

  submit() {
    this.submitted = true;
    if (this.feesForm.invalid) {
      this.showNotification('Add Fees Settings', 'Please check fields highlighted in red', 'info');
      return;
    }

    if (this.isEditFeesSettings) {
      this.updateFeesSetting();
      return;
    }
    this.createFeesSetting();
  }

  showNotification(title: string, message: string, type: NbComponentStatus) {
    this.toastService.showTranslatedToast(type, title, message);
  }

  private getFeesSettings() {
    this.spinnerService.show().then();
    this.settingsService
      .getSettings()
      .pipe(first())
      .subscribe({
        next: value => {
          this.settings = value.data;
          if (!this.settings.fees || this.settings.fees.length < 1) {
            this.isAddFeesSettings = true;
            this.addFee();
            this.spinnerService.hide().then();
            return;
          } else {
            this.isEditFeesSettings = true;
          }
          this.feesSettings = this.settings.fees;
          this.initFeesSettings();
          this.spinnerService.hide().then();
        },
        error: (error: HttpErrorModel) => {
          this.spinnerService.hide().then();
          this.httpError = error;
        },
      });
  }

  private createFeesSetting() {
    this.spinnerService.show().then();
    const transformedFeesData = this.feesArray.getRawValue().map((fee: FeesSettingModel) => ({
      ...fee,
      amount: fee.type == 1 ? fee.amount / 100 : fee.amount,
    }));
  
    const data = {
      fees: transformedFeesData,
    };
  
    this.settingsService
      .addSettingstoSpecificBranch(this.restaurantId, this.branchId, data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinnerService.hide().then();
          this.closeDialog();
          this.showNotification('Fees Settings', 'Fees Settings created successfully', 'success');
        },
        error: error => {
          this.spinnerService.hide().then();
          this.httpError = error;
          this.showNotification(error.message, error.errors.toString(), 'danger');
        }
      });
  }
  

  private updateFeesSetting() {
    const feesData = this.feesArray.getRawValue();
  
    const transformedFeesData = feesData.map((fee: FeesSettingModel) => ({
      ...fee,
      amount: fee.type == 1 ? fee.amount / 100 : fee.amount,
    }));
  
    const data = { fees: transformedFeesData };
    this.spinnerService.show().then();
    this.settingsService
      .editSettingstoSpecificBranch(this.restaurantId, this.branchId, data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinnerService.hide().then();
          this.closeDialog();
          this.showNotification('Fees Setting', 'Fees Setting updated successfully', 'success');
        },
        error: error => {
          this.spinnerService.hide().then();
          this.httpError = error;
          this.showNotification(error.message, error.errors.toString(), 'danger');
        }
      });
  }
  

  closeDialog() {
    this.dialogRef.close();
  }
}
