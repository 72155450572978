import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})

export class CompanyService {
    userprofile = JSON.parse(localStorage.getItem('user'));
    companies = this.userprofile?.profile?.associated_companies;  
    constructor() {
    }
}
