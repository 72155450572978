<nb-card class="page-wrapper">
  <nb-card-header class="page-header bg-white d-flex flex-column">
    <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
      <i class="bi bi-x-square-fill m-0"></i>
    </button>
    <p *ngIf="isAddreviewSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{'Add Google Review Settings' | translate}}
    </p>
    <p *ngIf="isEditreviewSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{'Update Google Review Settings' | translate}}
    </p>
  </nb-card-header>
  <nb-card-body class="page-body">
    <form
      action="javascript:"
      class="reset h-100"
      [formGroup]="reviewForm"
      [ngClass]="{ 'was-validated': submitted }"
      class="table-wrapper p-4">
      <div class="h-100 d-flex flex-column justify-content-around">
        <div>
          <!-- Review link -->
          <div class="my-2">
            <label class="form-label">{{'Review link' | translate}}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['review_link'].errors }"
              formControlName="review_link"
              placeholder="{{'Please enter review link' | translate}}" />
          </div>

          <!-- review Google Type -->
          <div class="my-2" [ngClass]="{'rtl': language.isArabic}">
            <label class="form-label">{{'Review Google Type' | translate}}</label>
            <select
              class="form-select input-control"
              aria-label="Default select example"
              formControlName="review_type"
              required
              [ngClass]="{ 'is-invalid': submitted && f['review_type'].errors }"
              (change)="onReviewTypeChange()">
              <option *ngFor="let status of reviewGoogleTypeEnum; index as i" [value]="status.value">
                {{ status.title | translate}}
              </option>
            </select>
          </div>

          <!-- Stars threshold (conditionally displayed) -->
          <div class="my-2" *ngIf="showStarsThreshold">
            <label class="form-label">{{'Stars threshold' | translate}}</label>
            <select
              class="form-select input-control"
              aria-label="Default select example"
              formControlName="stars_threshold"
              [ngClass]="{ 'is-invalid': submitted && f['stars_threshold'].errors }">
              <option *ngFor="let status of starsThreshold; index as i" value="{{ status }}">
                {{ status }}
              </option>
            </select>
          </div>
        </div>
        <!-- button -->
        <div>
          <button
            (click)="submit()"
            type="button"
            class="btn text-white tw-text-base tw-font-medium w-100 py-3 secondary-background-color tw-rounded-2xl">
            <p *ngIf="isAddreviewSettings && permissionStore">{{'Create Google Review Settings' | translate}}</p>
            <p *ngIf="isEditreviewSettings && permissionUpdate">{{'Update Google Review Settings' | translate}}</p>
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
