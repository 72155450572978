<nb-card class="page-wrapper">
  <nb-card-header class="page-header bg-white d-flex flex-column">
    <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
      <i class="bi bi-x-square-fill m-0"></i>
    </button>
    <p *ngIf="isAddSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{ 'Add Checkout Settings' | translate }}
    </p>
    <p *ngIf="isEditSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{ 'Update Checkout Settings' | translate }}
    </p>
  </nb-card-header>
  <nb-card-body class="page-body">
    <form
      action="javascript:"
      class="reset h-100"
      [formGroup]="Form"
      [ngClass]="{ 'was-validated': submitted}"
      class="table-wrapper p-4">
      <div class="h-100 d-flex flex-column justify-content-around">
        <div>
          <!-- base_url -->
          <div class="my-2">
            <label class="form-label">{{ 'Base url' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['base_url'].errors }"
              formControlName="base_url"
              placeholder="{{ 'Please enter Base url' | translate }}" />
          </div>

          <!-- sha_type -->
          <div class="my-2">
            <label class="form-label">{{ 'Sha type' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['sha_type'].errors }"
              formControlName="sha_type"
              placeholder="{{ 'Please enter sha type' | translate }}" />
          </div>

          <!-- public_key -->
          <div class="my-2">
            <label class="form-label">{{ 'Public key' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['public_key'].errors }"
              formControlName="public_key"
              placeholder="{{ 'Please enter public key' | translate }}" />
          </div>

          <!-- secret_key -->
          <div class="my-2">
            <label class="form-label">{{ 'Secret key' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['secret_key'].errors }"
              formControlName="secret_key"
              placeholder="{{ 'Please enter secret key' | translate }}" />
          </div>

          <!-- webhook_secret -->
          <div class="my-2">
            <label class="form-label">{{ 'Webhook secret' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['webhook_secret'].errors }"
              formControlName="webhook_secret"
              placeholder="{{ 'Please enter webhook secret' | translate }}" />
          </div>

          <!-- processing_channel_id -->
          <div class="my-2">
            <label class="form-label">{{ 'processing_channel_id' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['processing_channel_id'].errors }"
              formControlName="processing_channel_id"
              placeholder="{{ 'Please enter processing channel id' | translate }}" />
          </div>

          <!-- environment -->
          <div class="my-2">
            <label class="form-label">{{ 'Environment' | translate }}</label>
            <select
              class="form-select input-control"
              aria-label="Default select example"
              formControlName="environment"
              required
              [ngClass]="{ 'is-invalid': submitted && f['environment'].errors }">
              <option *ngFor="let status of environmentEnum; index as i" [value]="status.value">
                {{ status.title | translate }}
              </option>
            </select>
          </div>

          <!-- Is Active -->
          <div class="my-2">
            <label class="form-label">{{ 'Is Active' | translate }}</label>
            <select
              class="form-select input-control"
              aria-label="Default select example"
              formControlName="is_active"
              required
              [ngClass]="{ 'is-invalid': submitted && f['is_active'].errors }">
              <option [value]="0">
                {{ 'No' | translate }}
              </option>
              <option [value]="1">
                {{ 'Yes' | translate }}
              </option>
            </select>
          </div>

          <!-- button -->
          <div>
            <button
              (click)="submit()"
              type="button"
              class="btn text-white tw-text-base tw-font-medium w-100 py-3 secondary-background-color tw-rounded-2xl">
              <p *ngIf="isAddSettings && permissionStore">{{ 'Create Checkout Settings' | translate }}</p>
              <p *ngIf="isEditSettings && permissionUpdate">{{ 'Update Checkout Settings' | translate }}</p>
            </button>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
