import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BranchModel } from '@shared/models/branch.model';
import { ResponseModel } from '@shared/models/response.model';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { SettingsModel } from '@shared/models/settings.model';

@Injectable({
  providedIn: 'root',
})

export class SettingService {
  restaurant: RestaurantModel = JSON.parse(localStorage.getItem('restaurant'));
  restaurantID: string = this.restaurant.id;
  branch: BranchModel = JSON.parse(localStorage.getItem('branch'));
  branchID: string = this.branch.id;

  constructor(private http: HttpClient) {}

  getSettings() {
    return this.http.get<ResponseModel<SettingsModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/settings`
    );
  }

  editSettings(data: object) {
    return this.http.post<ResponseModel<SettingsModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/settings?_method=PUT`,
      data
    );
  }

  editSettingstoSpecificBranch(restaurantId:string, branchId:string, data: object) {
    return this.http.post<ResponseModel<SettingsModel>>(
      `restaurants/${restaurantId}/branches/${branchId}/settings?_method=PUT`,
      data
    );
  }

  addSettings(data: object) {
    return this.http.post<ResponseModel<SettingsModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/add-settings`,
      data
    );
  }
  
  addSettingstoSpecificBranch(restaurantId:string, branchId:string, data: object) {
    return this.http.post<ResponseModel<SettingsModel>>(
      `restaurants/${restaurantId}/branches/${branchId}/add-settings`,
      data
    );
  }
}
