<div class="tw-flex tw-justify-between tw-px-5 tw-mb-5 mt-3">
  <h2 class="tw-text-xl">{{ 'Notifications' | translate }}</h2>
  <div>
    <button (click)="onToggle('list')" class="btn border-0 m-0 p-0 ps-2">
      <i class="bi bi-border-width"></i>
    </button>
    /
    <button (click)="onToggle('grid')" class="btn border-0 m-0 p-0 ps-2">
      <i class="bi bi-grid-3x2-gap-fill"></i>
    </button>
  </div>
</div>
