import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'app-switcher-language',
  templateUrl: './switcher-language.component.html',
  styleUrls: ['./switcher-language.component.scss'],
})
export class SwitcherLanguageComponent {
  selectedLanguage: string = '';
  protected readonly locales = ['en', 'ar'];
  Language:string='Language';
  constructor(public translate: TranslateService, private userService: UserService, private authService: AuthService) {
    const savedLanguage =this.translate.currentLang;
    if (savedLanguage) {
      this.translate.use(savedLanguage);
      this.selectedLanguage=savedLanguage;
      if(savedLanguage==='ar') {
        this.Language='English'
      } else {
        this.Language='العربية'
      }
    }
  }

  toggleLanguage(): void {
    const selectedLanguage = (this.selectedLanguage === 'en') ? 'ar' : 'en';
    this.changeLanguage(selectedLanguage)
  }

  changeLanguage(language: string) {
    const localStorage = document.defaultView?.localStorage;
    this.translate.use(language);
    this.translate.currentLang = language;
    if (localStorage) {
      localStorage.setItem('language', language);
    }
    const Language = language;
    this.selectedLanguage = Language;
    this.selectedLanguage = this.selectedLanguage[0].toUpperCase() + this.selectedLanguage.slice(1);
    this.userService.getUserdetails().subscribe({
      next: res => {
        this.authService.refreshLoginData(res.data);
        window.location.reload();
      },
    });
  }
}
