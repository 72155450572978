import { Component, Output, EventEmitter, Input } from '@angular/core';
import { LanguageService } from '@shared/services/language.service';

interface DropdownItem {
  value: string; // Assuming the value is a string
  title: string; // Assuming the title is a string
}

@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.css'],
})
export class SortButtonComponent {
  @Input() dropdownItem: DropdownItem[] = [];
  @Output() statusSelected: EventEmitter<string> = new EventEmitter<string>();
  onStatusSelect(selectedItem: string) {
    this.statusSelected.emit(selectedItem);
  }
  constructor(public language: LanguageService) {}
}
