<div
  class="p-0 m-3 mx-auto tw-w-40 tw-h-40 tw-rounded-xl"
  [ngClass]="{
    'bg-white': status === 1,
    'secondary-background-color': status === 2,
    'strong-orange-background-color': status === 3,
    'mauve-background-color': status === 4,
  }">
  <div
    class="d-flex justify-content-center justify-align-center tw-h-8 tw-w-8"
    [ngClass]="{
      'tw-rounded-tl-xl': !language.isArabic,
      'tw-rounded-br-lg': !language.isArabic,
      'tw-rounded-tr-xl': language.isArabic,
      'tw-rounded-bl-lg': language.isArabic,
      'main-background-color': status === 1,
      'glass-background': status !== 1
    }">
    <p class="p-0 m-auto tw-text-base tw-font-medium text-white">{{ tableNumber }}</p>
  </div>
  <div class="w-100 h-75 p-2 d-flex flex-column m-auto">
    <img *ngIf="status !== 1" class="tw-size-10 m-auto" src="assets/images/tableimage.png" alt="" />
    <img *ngIf="status === 1" class="tw-size-10 m-auto" src="assets/images/tableactive.png" alt="" />
    <div class="mt-2">
      <div
        class="rounded-5 text-center py-1 mt-3"
        [ngClass]="[status === 1 ? 'main-background-color' : 'glass-background']">
        <p class="text-white tw-text-xs">{{ title | formatType }}</p>
      </div>
    </div>
  </div>
</div>
