<nb-card class="page-wrapper">
  <nb-card-header class="page-header bg-white d-flex flex-column">
    <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
      <i class="bi bi-x-square-fill m-0"></i>
    </button>
    <p class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{'Add Payment gateway Settings' | translate}}
    </p>
  </nb-card-header>
  <nb-card-body class="page-body">
    <form
      action="javascript:"
      class="reset"
      [formGroup]="paymentGatewayForm"
      class="p-4">
      <div class="h-100 d-flex flex-column justify-content-around">
        <div>

          <!-- payment_method -->
          <div class="my-2" [ngClass]="{'rtl': language.isArabic}">
            <label class="form-label">{{'Payment gateway' | translate}}</label>
            <select
              class="form-select input-control"
              aria-label="Default select example"
              formControlName="payment_method"
              required
              [ngClass]="{ 'is-invalid': submitted && f['payment_method'].errors }"
              >
              <option *ngFor="let status of paymentMeyhodEnum; index as i" [value]="status.value">
                {{ status.title | translate}}
              </option>
            </select>
          </div>

        </div>
        <!-- button -->
        <div>
          <button
            type="submit" (click)="submit()"
            class="btn text-white tw-text-base tw-font-medium w-100 py-3 secondary-background-color tw-rounded-2xl">
            <p>{{'Add Payment gateway Settings' | translate}}</p>
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
