//import '../../shared/css-shared/toaster.css';

import { Injectable } from '@angular/core';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  config!: NbToastrConfig;

  index = 1;
  destroyByClick = true;
  duration = 3000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';

  constructor(
    private toastService: NbToastrService,
    private translate: TranslateService
  ) {}

  // New: Method for showing toast with translation
  showTranslatedToast(type: NbComponentStatus, titleKey: string, messageKey: string, duration?: number) {
    // Use TranslateService to get the title and message translations
    this.translate.get([titleKey, messageKey]).subscribe(translations => {
      const title = translations[titleKey];
      const message = translations[messageKey];

      this.showToast(type, title, message, duration);
    });
  }

  showToast(type: NbComponentStatus, title: string, body: string, duration?: number) {
    const config = {
      status: type,
      enableHtml: true,
      destroyByClick: this.destroyByClick,
      duration: duration ?? this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
      toastClass: 'custom-toast',
    };

    this.toastService.show(body, title, config);
  }
}
