import { Component, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DattaConfig } from '../../../app-config';
import { MobileService } from '@shared/services/mobile.service';
import { MobileMenuItemInputs } from './mobile-menu/mobile-menu-item/mobile-menu-item.component';
import { AuthService } from '@shared/services/auth.service';
import { Router } from '@angular/router';
import { AppDetailsDialogComponent } from '@shared/components/app-details-dialog/app-details-dialog.component';
import { NbWindowService } from '@nebular/theme';
import { RestaurantService } from '@shared/services/restaurant/restaurant.service';
import { CompanyService } from '@shared/services/company.service';
import { StaffModel } from '@shared/models/staff.model';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { BranchModel } from '@shared/models/branch.model';
import { StorageKeysEnum } from '@shared/enum/storage-keys.enum';
import { LocalStorageRefService } from '@shared/services/local-storage-ref.service';
import { FCMService } from '@shared/services/fcm.service';
import { LanguageService } from '@shared/services/language.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { map } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsEnum } from '@shared/enum/permissions.enum';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit{
  navCollapsed: any;
  navCollapsedMob: boolean;
  windowWidth: number;

  restaurant: RestaurantModel = JSON.parse(localStorage.getItem('restaurant'));
  branch: BranchModel = JSON.parse(localStorage.getItem('branch'));

  permissionControlPanel : boolean = false;
  permissionOrder : boolean = false;
  permissionTables : boolean = false;
  permissionRestaurants : boolean = false;
  permissionMenus : boolean = false;
  permissionStaff : boolean = false;
  permissionReview : boolean = false;
  permissionReservations : boolean = false;
  permissionTermsAndConditions : boolean = false;
  private async checkUserPermission() {
    this.permissionControlPanel = await this.permissionService.hasPermission(PermissionsEnum.SHOW_PANEL);
    this.mobileMenuItems.find(item => item.label === 'Control Panel')!.permission = this.permissionControlPanel;
    this.permissionOrder = await this.permissionService.hasPermission(PermissionsEnum.INDEX_ORDER);
    this.mobileMenuItems.find(item => item.label === 'Orders')!.permission = this.permissionOrder;
    this.permissionTables = await this.permissionService.hasPermission(PermissionsEnum.INDEX_RESTAURANT_TABLE);
    this.mobileMenuItems.find(item => item.label === 'Tables')!.permission = this.permissionTables;
    this.permissionRestaurants = await this.permissionService.hasPermission(PermissionsEnum.INDEX_RESTAURANT);
    this.mobileMenuItems.find(item => item.label === 'Restaurants')!.permission = this.permissionRestaurants;
    this.permissionMenus = await this.permissionService.hasPermission(PermissionsEnum.INDEX_MENU );
    this.mobileMenuItems.find(item => item.label === 'Menus')!.permission = this.permissionMenus;
    this.permissionStaff = await this.permissionService.hasPermission(PermissionsEnum.INDEX_STAFF );
    this.mobileMenuItems.find(item => item.label === 'Staff')!.permission = this.permissionStaff;
    this.permissionReview = await this.permissionService.hasPermission(PermissionsEnum.INDEX_REVIEW );
    this.mobileMenuItems.find(item => item.label === 'Review')!.permission = this.permissionReview;
    this.permissionTermsAndConditions = await this.permissionService.hasPermission(PermissionsEnum.SHOW_TERMS_AND_CONDITIONS );
    this.mobileMenuItems.find(item => item.label === 'Terms And Conditions')!.permission = this.permissionTermsAndConditions;
    this.permissionReservations = await this.permissionService.hasPermission(PermissionsEnum.INDEX_RESERVATION );
    this.mobileMenuItems.find(item => item.label === 'Reservations')!.permission = this.permissionReservations;
  }
  mobileMenuItems: MobileMenuItemInputs[] = [
    {
      label: 'Dashboard',
      icon: 'assets/new-icons/menu-menus.svg',
      route: '/dashboard',
      permission: true,
    },
    {
      label: 'Control Panel',
      icon: 'assets/new-icons/menu-controlpanel.svg',
      route: '/panel',
      permission: false,
    },
    {
      label: 'Orders',
      icon: 'assets/new-icons/menu-orders.svg',
      route: '/orders',
      permission: false,
    },
    {
      label: 'Tables',
      icon: 'assets/new-icons/menu-tables.svg',
      route: '/tables',
      permission: false,
    },
    {
      label: 'Restaurants',
      icon: 'assets/new-icons/menu-rest.svg',
      route: '/restaurant',
      permission: false,
    },
    {
      label: 'Profile',
      icon: 'assets/new-icons/menu-profile.svg',
      route: '/profile',
      permission: true,
    },
    {
      label: 'Menus',
      icon: 'assets/new-icons/menu-menus.svg',
      route: '/menus',
      permission: false,
    },
    {
      label: 'Staff',
      icon: 'assets/new-icons/menu-staff.svg',
      route: '/staff',
      permission: false,
    },
    {
      label: 'Reservations',
      icon: 'assets/new-icons/menu-terms.svg',
      route: '/reservations',
      permission: false,
    },
    {
      label: 'Review',
      icon: 'assets/new-icons/menu-staff.svg',
      route: '/review',
      permission: false,
    },
    {
      label: 'Terms And Conditions',
      icon: 'assets/new-icons/menu-terms.svg',
      route: '/terms-and-condition',
      permission: false,
    },
    {
      label: 'Logout',
      icon: 'assets/new-icons/menu-logout.svg',
      route: '',
      permission: true,
      clickCallback: () => {
        this.authService.logout();
      },
    },
  ];

  logout() {
    this.authService.logout();
  }
  userProfile() {
    this.router.navigate(['/profile']);
  }
  appVersion() {
    this.windowService.open(AppDetailsDialogComponent, {
      title: 'maedah Admin',
      context: { id: null },
      windowClass: 'custom-window',
      closeOnBackdropClick: false,
    });
  }

  get pageName() {
    const url = this.router.url;
    const removedSlash = url.substring(1);
    const firstSegment = removedSlash.split('/')[0];
    const formattedTitle = firstSegment
      .split('-')
      .map(s => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
    return formattedTitle;
  }
  notificationsCount$ = this.notificationService.notifications$.pipe(
    map(res=>res.meta.pagination.unread_count)
  )

  userType: string = '';
  staff: StaffModel[] = [];
  branches: BranchModel[] = [];
  selectedCompany: any;
  selectedRestaurant: any;
  selectedBranch: any;
  notificationsEnabled = true; // By default, notifications are enabled
  constructor(
    private zone: NgZone,
    private location: Location,
    public mobileService: MobileService,
    private authService: AuthService,
    private router: Router,
    private windowService: NbWindowService,
    public restaurantService: RestaurantService,
    private localStorageRefService: LocalStorageRefService,
    public companyService: CompanyService,
    private fcmService: FCMService,
    public language: LanguageService,
    private permissionService: NgxPermissionsService,
    private notificationService: NotificationsService,
  ) {
    this.checkUserPermission().then();
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }

    if (
      current_url === this.location['_baseHref'] + '/layout/collapse-menu' ||
      current_url === this.location['_baseHref'] + '/layout/box'
    ) {
      DattaConfig.isCollapseMenu = true;
    }

    this.windowWidth = window.innerWidth;
    this.navCollapsed = this.windowWidth >= 992 ? DattaConfig.isCollapseMenu : false;
    this.navCollapsedMob = false;
  }

  toggleNotifications() {
    this.notificationsEnabled = !this.notificationsEnabled;
    this.fcmService.toggleNotifications(this.notificationsEnabled); // Update FCMService with new state
  }

  ngOnInit() {
    this.userType = localStorage.getItem('userType');
  
    if (this.userType === 'staff') {
      this.staff = this.authService.getStaff();
    }
  
    // Retrieve restaurant and branches from local storage
    const restaurant: RestaurantModel = JSON.parse(localStorage.getItem('restaurant'));
    this.branches = restaurant?.branches || [];
  
    // Initialize selected restaurant by matching the ID with restaurantService.restaurants
    if (this.restaurantService.restaurants && this.restaurantService.restaurants.length > 0) {
      this.selectedRestaurant = this.restaurantService.restaurants.find(
        (r) => r.id === restaurant.id
      );
    }
  
    // Initialize selected branch by finding the branch in localStorage
    const savedBranch = JSON.parse(localStorage.getItem('branch')); // Assuming branch is saved in local storage
    if (savedBranch) {
      this.selectedBranch = this.branches.find(branch => branch.id === savedBranch.id);
    } else if (this.branches && this.branches.length > 0) {
      this.selectedBranch = this.branches[0]; // Default to first branch
    }
  }
  

  setCompany(item: any) {
    this.authService.saveCompany(item, 'admin');
    window.location.reload();
  }

  setRestaurant(item: any) {
    this.localStorageRefService.removeData(StorageKeysEnum.BRANCH);
    if(item.branches && item.branches[0]) {
      this.authService.saveBranch(item.branches[0])
    }
    this.authService.saveRestaurant(item);
    window.location.reload();
  }

  setBranch(branch: any) {
    if (branch && branch.id) {
      this.authService.saveBranch(branch); // Ensure you're saving the full branch object, not just the ID
      window.location.reload();
    }
  }

  setStaff(staff) {
    this.authService.saveStaff(staff);
  }

  navMobClick() {
    if (
      this.navCollapsedMob &&
      !document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')
    ) {
      this.navCollapsedMob = !this.navCollapsedMob;
      setTimeout(() => {
        this.navCollapsedMob = !this.navCollapsedMob;
      }, 100);
    } else {
      this.navCollapsedMob = !this.navCollapsedMob;
    }
  }
}
