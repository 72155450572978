import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbComponentStatus } from '@nebular/theme';
import { CurrencyEnum } from '@shared/enum/currency.enum';
import { LanguageEnum } from '@shared/enum/language.enum';
import { PermissionsEnum } from '@shared/enum/permissions.enum';
import { DialogData } from '@shared/models/dialog-data.model';
import { EnumModel } from '@shared/models/enum.model';
import { HttpErrorModel } from '@shared/models/http-error.model';
import { PayfortSettingModel } from '@shared/models/payfortSetting.model';
import { SettingsModel } from '@shared/models/settings.model';
import { LanguageService } from '@shared/services/language.service';
import { SettingService } from '@shared/services/setting/setting.service';
import { ToastService } from '@shared/services/toast.service';
import { MethodsUtils } from '@shared/utils/methods.utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs';

@Component({
  selector: 'app-payment-gateway-settings-dialog',
  templateUrl: 'payfort-settings-dialog.component.html',
  styleUrls: ['payfort-settings-dialog.component.scss'],
})
export class PayfortSettingsDialogComponent implements OnInit{

  permissionUpdate: string;
  permissionStore: string;
  submitted = false;
  payfortForm: UntypedFormGroup;
  settings: SettingsModel | null = null;
  payfortSettings: PayfortSettingModel | null = null;
  httpError: HttpErrorModel | null = null;
  formData = new FormData();
  isAddSettings: boolean = false;
  isEditSettings: boolean = false;
  currancyEnum: EnumModel[];
  languageEnum: EnumModel[];
  restaurantId: string;
  branchId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private toastService: ToastService,
    private settingsService: SettingService,
    public language: LanguageService,
    private methodUtils: MethodsUtils,
    public dialogRef: MatDialogRef<PayfortSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.currancyEnum = this.methodUtils.getStatuses(CurrencyEnum);
    this.languageEnum = this.methodUtils.getStatuses(LanguageEnum);
    this.permissionUpdate = PermissionsEnum.UPDATE_BRANCH_SETTING;
    this.permissionStore = PermissionsEnum.STORE_BRANCH_SETTINGS;
    this.branchId = data.branchId;
    this.restaurantId = data.restaurantId;
    this.payfortForm = this.fb.group({
      base_url: [null, [Validators.required]],
      is_sandbox: [null, [Validators.required]],
      language: [null, [Validators.required]],
      merchant_identifier: [null, [Validators.required]],
      access_code: [null, [Validators.required]],
      sha_type: [null, [Validators.required]],
      sha_request_phrase: [null, [Validators.required]],
      sha_response_phrase: [null, [Validators.required]],
      currency: [null, [Validators.required]],
      return_url: [null, [Validators.required]],
      return_url_tokenization: [null, [Validators.required]],
      exception_return_type: [null, [Validators.required]],
      path: [null, [Validators.required]],
      is_active: [null, [Validators.required]],
    });
  }

  get f() {
    return this.payfortForm.controls;
  }

  initSettings() {
    this.f['base_url'].setValue(this.payfortSettings.base_url);
    this.f['is_sandbox'].setValue(this.payfortSettings.is_sandbox);
    this.f['language'].setValue(this.payfortSettings.language);
    this.f['merchant_identifier'].setValue(this.payfortSettings.merchant_identifier);
    this.f['access_code'].setValue(this.payfortSettings.access_code);
    this.f['sha_type'].setValue(this.payfortSettings.sha_type);
    this.f['sha_request_phrase'].setValue(this.payfortSettings.sha_request_phrase);
    this.f['sha_response_phrase'].setValue(this.payfortSettings.sha_response_phrase);
    this.f['currency'].setValue(this.payfortSettings.currency);
    this.f['return_url'].setValue(this.payfortSettings.return_url);
    this.f['return_url_tokenization'].setValue(this.payfortSettings.return_url_tokenization);
    this.f['exception_return_type'].setValue(this.payfortSettings.exception_return_type);
    this.f['path'].setValue(this.payfortSettings.path);
    this.f['is_active'].setValue(this.payfortSettings.is_active);
  }

  ngOnInit() {
    this.getSettings();
  }

  submit() {
    this.submitted = true;
    if (this.payfortForm.invalid) {
      this.showNotification('Add Payfort Settings', 'Please check fields highlighted in red', 'info');
      return;
    }

    if (this.isEditSettings) {
      this.updateSetting();
      return;
    }
    this.createSetting();
  }

  showNotification(title: string, message: string, type: NbComponentStatus) {
    this.toastService.showTranslatedToast(type, title, message);
  }

  private getSettings() {
    this.spinnerService.show().then();
    this.settingsService
      .getSettings()
      .pipe(first())
      .subscribe({
        next: value => {
          this.settings = value.data;
          if (!this.settings.payfort_config) {
            this.isAddSettings = true;
            this.spinnerService.hide().then();
            return;
          } else {
            this.isEditSettings = true;
          }
          this.payfortSettings = this.settings.payfort_config;
          this.initSettings();
          this.spinnerService.hide().then();
        },
        error: (error: HttpErrorModel) => {
          this.spinnerService.hide().then();
          this.httpError = error;
        },
      });
  }

  private createSetting() {
    this.spinnerService.show().then();
    this.formData.set('base_url', this.f['base_url'].value);
    this.formData.set('is_sandbox', this.f['is_sandbox'].value);
    this.formData.set('language', this.f['language'].value);
    this.formData.set('merchant_identifier', this.f['merchant_identifier'].value);
    this.formData.set('access_code', this.f['access_code'].value);
    this.formData.set('sha_type', this.f['sha_type'].value);
    this.formData.set('sha_request_phrase', this.f['sha_request_phrase'].value);
    this.formData.set('sha_response_phrase', this.f['sha_response_phrase'].value);
    this.formData.set('currency', this.f['currency'].value);
    this.formData.set('return_url', this.f['return_url'].value);
    this.formData.set('return_url_tokenization', this.f['return_url_tokenization'].value);
    this.formData.set('exception_return_type', this.f['exception_return_type'].value);
    this.formData.set('path', this.f['path'].value);
    this.formData.set('is_active', this.f['is_active'].value);
    const data = {
      payfort_config: Object.fromEntries(this.formData),
    }
    this.settingsService
      .addSettingstoSpecificBranch(this.restaurantId, this.branchId, data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinnerService.hide().then();
          this.dialogRef.close(true);
          this.showNotification('Payfort Settings', 'Payfort Settings created successfully', 'success');
        },
        error: error => {
          this.spinnerService.hide().then();
          this.httpError = error;
          this.showNotification(error.message, error.errors.toString(), 'danger');
        },
      });
  }

    private updateSetting() {
      const payfortSettings = this.getChanges();
      const data = {
        payfort_config: Object.fromEntries(payfortSettings),
      }
      if (!payfortSettings.entries().next().value) {
        this.showNotification('Update Payfort Setting', 'No changes has been made', 'info');
        return;
      }
      this.spinnerService.show().then();
      this.settingsService
        .editSettingstoSpecificBranch(this.restaurantId, this.branchId, data)
        .pipe(first())
        .subscribe({
          next: () => {
            this.spinnerService.hide().then();
            this.dialogRef.close(true);
            this.showNotification('Payfort Setting', 'Payfort Setting updated successfully', 'success');
          },
          error: error => {
            this.spinnerService.hide().then();
            this.httpError = error;
            this.showNotification(error.message, error.errors.toString(), 'danger');
          },
        });
    }

  private getChanges() {
    if (this.f['base_url'].value !== this.payfortSettings?.base_url) {
      this.formData.set('base_url', this.f['base_url'].value);
    }
    if (this.f['is_sandbox'].value !== this.payfortSettings?.is_sandbox) {
      this.formData.set('is_sandbox', this.f['is_sandbox'].value);
    }
    if (this.f['language'].value !== this.payfortSettings?.language) {
      this.formData.set('language', this.f['language'].value);
    }
    if (this.f['merchant_identifier'].value !== this.payfortSettings?.merchant_identifier) {
      this.formData.set('merchant_identifier', this.f['merchant_identifier'].value);
    }
    if (this.f['access_code'].value !== this.payfortSettings?.access_code) {
      this.formData.set('access_code', this.f['access_code'].value);
    }
    if (this.f['sha_type'].value !== this.payfortSettings?.sha_type) {
      this.formData.set('sha_type', this.f['sha_type'].value);
    }
    if (this.f['sha_request_phrase'].value !== this.payfortSettings?.sha_request_phrase) {
      this.formData.set('sha_request_phrase', this.f['sha_request_phrase'].value);
    }
    if (this.f['sha_response_phrase'].value !== this.payfortSettings?.sha_response_phrase) {
      this.formData.set('sha_response_phrase', this.f['sha_response_phrase'].value);
    }
    if (this.f['currency'].value !== this.payfortSettings?.currency) {
      this.formData.set('currency', this.f['currency'].value);
    }
    if (this.f['return_url'].value !== this.payfortSettings?.return_url) {
      this.formData.set('return_url', this.f['return_url'].value);
    }
    if (this.f['return_url_tokenization'].value !== this.payfortSettings?.return_url_tokenization) {
      this.formData.set('return_url_tokenization', this.f['return_url_tokenization'].value);
    }
    if (this.f['exception_return_type'].value !== this.payfortSettings?.exception_return_type) {
      this.formData.set('exception_return_type', this.f['exception_return_type'].value);
    }
    if (this.f['path'].value !== this.payfortSettings?.path) {
      this.formData.set('path', this.f['path'].value);
    }
    if (this.f['is_active'].value !== this.payfortSettings?.is_active) {
      this.formData.set('is_active', this.f['is_active'].value);
    }
    return this.formData;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
