import { Component, Input, OnInit } from '@angular/core';
import { NotificationsModel } from '@shared/models/notifications.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications-cards',
  templateUrl: './notifications-cards.component.html',
  styleUrls: ['./notifications-cards.component.css'],
})
export class NotificationsCardsComponent implements OnInit{
  @Input() notifications$: Observable<NotificationsModel[]>;
  @Input() notificationsType: string = '';
  @Input() isGridView: boolean; // true for grid, false for list
  @Input() sectionTitle: string;
  showSwipeIndicator = true;

  ngOnInit() {
    // Hide swipe indicator after 3 seconds
    setTimeout(() => {
      this.showSwipeIndicator = false;
    }, 20000);
  }

  hideSwipeIndicator() {
    this.showSwipeIndicator = false;
  }

}
