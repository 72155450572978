<nb-card class="mb-0 p-4 border-0 text-center">
  <nb-card-body class="px-4 d-flex flex-column">
    <i *ngIf="data.type === 'delete'" class="bi bi-trash3-fill"></i>
    <i *ngIf="data.type === 'info'" class="bi bi-info-circle-fill"></i>
    <strong class="mt-3">{{data.message | translate}}</strong>
  </nb-card-body>
  <nb-card-footer class="border-0 d-flex justify-content-center align-items-center gap-2">
    <button (click)="onClick(false)" class="black py-2">{{'NO' | translate}}</button>
    <button (click)="onClick(true)" class="primary py-2">{{'YES' | translate}}</button>
  </nb-card-footer>
</nb-card>
