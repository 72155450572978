import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuItemModel } from '@shared/models/menu-item.model';
import { CartService } from '@shared/services/cart.service';
import { AddNoteDialogComponent } from '../add-note-dialog/add-note-dialog.component';
import { LanguageService } from '@shared/services/language.service';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.css'],
})
export class ItemCardComponent implements OnInit {
  @Input() item!: MenuItemModel;
  @Input() index: number = -1;
  isImgLoaded: boolean = false;

  constructor(
    public cartService: CartService,
    public dialog: MatDialog,
    public language : LanguageService
  ) {}
  ngOnInit() {
    this.cartService.getCartProduct();
  }
  addToCart(item: MenuItemModel) {
    this.cartService.addToCart(item, null);
  }
  deleteFromCart(id: string) {
    this.cartService.removeFromCart(id);
  }
  specifyOrder(item: MenuItemModel) {
    this.dialog.open(AddNoteDialogComponent, {
      width: '345px',
      data: {
        item: item,
      },
    });
  }
}
