<div
  *ngIf="mainLabel"
  (click)="onClick()"
  routerLink="{{ route }}"
  class="tw-flex tw-justify-between tw-items-center tw-px-3 tw-cursor-pointer my-2">
  <div class="tw-w-3/4 tw-flex tw-items-center tw-gap-2">
    <img class="tw-w-[32px]" src="{{ icon }}" alt="" />
    <span class="tw-text-primary">{{ label | translate}}</span>
  </div>
  <div class="tw-w-1/4 tw-flex tw-justify-end tw-items-center">
    <nb-icon *ngIf="!isArabic" icon="arrow-ios-forward" class="tw-w-[30px] tw-text-primary"></nb-icon>
    <nb-icon *ngIf="isArabic" icon="arrow-ios-back" class="tw-w-[30px] tw-text-primary"></nb-icon>
  </div>
</div>

<div
  *ngIf="showLabel"
  (click)="onClick()"
  routerLink="{{ route }}"
  class="tw-flex tw-justify-between tw-items-center tw-px-3 tw-cursor-pointer my-2">
  <div class="tw-w-3/4 tw-flex tw-items-center tw-gap-2">
    <img class="tw-w-[32px]" src="{{ icon }}" alt="" />
    <span class="tw-text-primary">{{ label | translate}}</span>
  </div>
  <div class="tw-w-1/4 tw-flex tw-justify-end tw-items-center">
    <nb-icon *ngIf="!isArabic" icon="arrow-ios-forward" class="tw-w-[30px] tw-text-primary"></nb-icon>
    <nb-icon *ngIf="isArabic" icon="arrow-ios-back" class="tw-w-[30px] tw-text-primary"></nb-icon>
   </div>
</div>
