<div class="row px-3">
  <ng-container *ngIf="notifications$ | async as notifications">
    <ng-container *ngIf="notifications.length > 0">
      <div class="tw-flex tw-w-full tw-items-center tw-gap-5 justify-content-center">
        <span class="tw-text-xl mb-3 tw-text-primary">{{ sectionTitle | translate }}</span>
      </div>

      <div *ngFor="let notification of notifications; let i = index" [ngClass]="isGridView ? 'col-6 col-md-3' : 'tw-relative tw-mb-2'">
        <div class="notification-card tw-relative mb-4">
          <!-- Swipe Hand: Only visible on the first card -->
          <div *ngIf="i === 0 && showSwipeIndicator && !isGridView" class="swipe-hand">
             <img src="assets/images/newVector/swipe.png">
          </div>
          <!-- Notification Card -->
          <app-notifications-card
            [notification]="notification"
            [isGridView]="isGridView"
            [notificationsType]="notificationsType">
          </app-notifications-card>
        </div>
      </div>
      
      <hr class="tw-w-full my-3" />
    </ng-container>
  </ng-container>
</div>
