import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BranchModel } from '@shared/models/branch.model';
import { RestaurantModel } from '@shared/models/restaurant.model';

export type MobileMenuItemInputs = {
  label: string;
  icon: string;
  route: string;
  clickCallback?: () => void;
};

@Component({
  selector: 'app-mobile-menu-item',
  templateUrl: './mobile-menu-item.component.html',
  styleUrls: ['./mobile-menu-item.component.scss'],
})
export class MobileMenuItemComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string;
  @Input() route: string;
  @Input() clickCallback: () => void = () => {};
  restaurant: RestaurantModel;
  branch: BranchModel;
  currentLAnguage:string ='';
  get isArabic() {
    return this.currentLAnguage === 'ar';
  }

  get showLabel() {
    return (
      this.label !== 'Dashboard' &&
      this.label !== 'Restaurants' &&
      this.label !== 'Logout' &&
      this.label !== 'Profile' &&
      this.restaurant &&
      this.branch
    );
  }
  get mainLabel() {
    return (
      this.label === 'Dashboard' || this.label === 'Restaurants' || this.label === 'Profile' || this.label === 'Logout'
    );
  }

  constructor(public translate:TranslateService) {
    this.currentLAnguage = this.translate.currentLang;
  }

  ngOnInit() {
    this.restaurant = JSON.parse(localStorage.getItem('restaurant'));
    this.branch = JSON.parse(localStorage.getItem('branch'));
  }

  onClick() {
    this.clickCallback();
  }
}
