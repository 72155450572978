<nb-card class="page-wrapper">
  <nb-card-header class="page-header bg-white d-flex flex-column">
    <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
      <i class="bi bi-x-square-fill m-0"></i>
    </button>
    <p *ngIf="isAddFeesSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{ 'Add Fees Settings' | translate }}
    </p>
    <p *ngIf="isEditFeesSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{ 'Update Fees Settings' | translate }}
    </p>
  </nb-card-header>
  <nb-card-body class="page-body">
    <form
      action="javascript:"
      class="reset h-100"
      [formGroup]="feesForm"
      [ngClass]="{ 'was-validated': submitted, rtl: language.isArabic }"
      class="table-wrapper p-4">
      <!-- Loop through feesArray to dynamically render the form fields for each fee -->
      <div formArrayName="feesArray">
        <div *ngFor="let fee of feesArray.controls; let i = index" [formGroupName]="i">
          <div class="my-2">
            <label class="form-label">{{ 'Title' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && fee.get('title').errors }"
              formControlName="title"
              placeholder="{{ 'Please enter Title' | translate }}" />
          </div>
      
          <div class="my-2 d-flex">
            <div class="col-6">
              <label class="form-label">{{ 'Type' | translate }}</label>
              <select
                class="form-select input-control"
                formControlName="type"
                required
                [ngClass]="{ 'is-invalid': submitted && fee.get('type').errors }">
                <option *ngFor="let status of feesTypeEnum; index as i" [value]="status.value">
                  {{ status.title | translate }}
                </option>
              </select>
            </div>
      
            <div class="col-6 px-3">
              <label class="form-label">{{ 'Amount' | translate }}</label>
              <div class="input-group">
                <input
                  required
                  type="number"
                  class="form-control input-control"
                  [ngClass]="{ 'is-invalid': submitted && fee.get('amount').errors }"
                  formControlName="amount"
                  />
                <!-- Dynamically display % icon -->
                <span *ngIf="isPercent(i)" class="input-group-text">%</span>
              </div>
            </div>
          </div>
      
          <button
            *ngIf="isAddFeesSettings && feesArray.length > 1"
            (click)="removeFee(i)"
            type="button"
            class="btn btn-danger">
            {{ 'Remove Fee' | translate }}
          </button>
          <hr class="w-100 tw-border-2 my-3 main-color" />
        </div>
      </div>
      
      <!-- Button to add a new fee -->
      <button type="button" class="btn btn-secondary mt-3" (click)="addFee()">{{ 'Add Fee' | translate }}</button>

      <!-- Submit button -->
      <div>
        <button
          (click)="submit()"
          type="button"
          class="btn text-white tw-text-base tw-font-medium w-100 py-3 secondary-background-color tw-rounded-2xl">
          <p *ngIf="isAddFeesSettings && permissionStore">{{ 'Create Fees Settings' | translate }}</p>
          <p *ngIf="isEditFeesSettings && permissionUpdate">{{ 'Update Fees Settings' | translate }}</p>
        </button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
