import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NotificationsService } from '@services/notifications.service';
import { NotificationsModel } from '@models/notifications.model';
import { Router } from '@angular/router';
import { NotificationTypeEnum } from '@shared/enum/notification-type.enum';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FCMService } from '@shared/services/fcm.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  message: string | null = null;
  notification_count: number = 0;
  isBrowser: boolean = false;
  notifications: NotificationsModel[] = [];
  loading: boolean = false;
  created_at: string = '';
  formattedDate: string = '';
  notread: boolean = false;
  page: number = 1; // Keep track of the current page
  perPage: number = 10; // Number of notifications to load per page
  hasMore: boolean = true; 
  totalNotifications: number = 0;
  type: string;
  isFirstTime : boolean = true;
  isScroll : boolean = false;
  // Subscription for the FCM notification observable
  private fcmSubscription!: Subscription;

  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private translateService: TranslateService,
    public language: LanguageService,
    private fcmService: FCMService
  ) {}
  ngOnInit(): void {
    this.notifications= [];
    this.isFirstTime = true;
    this.loadnotifications();
     // Subscribe to the FCM notification observable
     this.fcmSubscription = this.fcmService.getNotificationObservable().subscribe({
      next: () => {
        this.notifications= [];
        this.isFirstTime = true;
        this.loadnotifications(true);  // Refresh notifications when a new FCM message arrives
      },
      error: (error) => {
        console.error('Error receiving FCM notification', error);
      },
    });
  }

  ngOnDestroy(): void {
    // Clean up the subscription when the component is destroyed
    if (this.fcmSubscription) {
      this.fcmSubscription.unsubscribe();
    }
  }

  loadnotifications(reset: boolean = false) {
    if (reset) {
      this.page = 1;
      this.notifications = [];
      this.hasMore = true;
      this.totalNotifications = 0
    }
    if (this.hasMore && this.isFirstTime || this.hasMore && this.isScroll) {
      this.loading = true;
      this.notificationsService.show_all_notifications(this.page, this.perPage).subscribe({
        next: res => {
          this.isFirstTime = false;
          this.notifications = [...this.notifications, ...res.data];
          this.notification_count = res.meta.pagination.unread_count;
          this.totalNotifications = res.meta.pagination.total;
          if(this.notifications.length < this.totalNotifications) {
            this.hasMore = true;
          } else {
            this.hasMore = false;
          }
          this.notread = this.notifications.some(n => !n.read);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
    } 
  }
  
  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;
    // Check if scrolled to the bottom
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      this.isScroll = true;
      this.page++;
      this.loadnotifications(); // Load next page
    }
  }

  mark_as_read(id: string) {
    this.loading = true;
    this.notificationsService.notification_as_read(id).subscribe({
      next: () => {
        this.loadnotifications();
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  mark_all_as_read() {
    this.loading = true;
    this.notificationsService.mark_all_as_read().subscribe({
      next: () => {
        this.loadnotifications();
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  formatLocalDate(dateString: string) {
    const language = this.translateService.currentLang;
    const utcMoment = moment.utc(dateString);
    const localMoment = utcMoment.local();
    localMoment.locale(language);
    return localMoment;
  }

  handleNotificationClick(notification: NotificationsModel) {
    this.mark_as_read(notification.id);
    if (
      notification.data.type === NotificationTypeEnum.NEW_ORDER ||
      notification.data.type === NotificationTypeEnum.CANCEL_ORDER ||
      notification.data.type === NotificationTypeEnum.NOTIFY_WAITER_TO_UPDATE_ORDER ||
      notification.data.type === NotificationTypeEnum.NOTIFY_WAITER_TO_PAY_CASH
    ) {
      this.router.navigate(['orders/', notification.data.order_id]);
      this.dropdown.close();
    } else if (
      notification.data.type === NotificationTypeEnum.UNLOCK_RESTAURANT_TABLE ||
      notification.data.type === NotificationTypeEnum.NOTIFY_WAITER
    ) {
      this.router.navigate(['tables/', notification.data.table_id]);
      this.dropdown.close();
    }
  }

  onDropdownClose() {
    this.notifications= [];
    this.page = 1;
    this.hasMore = true;
    this.isFirstTime = true;
    this.loadnotifications();
  }
}
