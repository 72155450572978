<nb-card class="mb-0 p-4 pt-2 pb-0">
    <nb-card-body class="p-0">
        <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
            <i class="bi bi-x-square-fill m-0"></i>
        </button>
        <div class="row my-2 m-0">
            <div class="col-md-12 px-1">
                <label class="form-label">
                    {{'Choose a user' | translate}}
                </label>
                <select
                [(ngModel)]="chosenStaffId"
                class="form-select input-control"  aria-label="Default select example"
                    required>
                    <option [value]="null">-</option>
                    <option *ngFor="let staff of staff$ | async; index as i" value="{{ staff.id }}">
                        {{ staff.user.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row my-2 m-0">
            <button
            [disabled]="!chosenStaffId"
            (click)="assign()"
            class="
            btn b-0 m-0 border-0 tw-bg-primary tw-text-white
            disabled:tw-bg-gray-300 disabled:tw-text-gray-500
            ">{{'Assign' | translate}}</button>
        </div>
    </nb-card-body>
</nb-card>