import { Component, Input } from '@angular/core';
import { LanguageService } from '@shared/services/language.service';
@Component({
  selector: 'app-panel-card',
  templateUrl: './panel-card.component.html',
  styleUrls: ['./panel-card.component.css'],
})
export class PanelCardComponent {
  @Input() orderStatus : number =0;
  @Input() tableStatus: number =0;
  @Input() tableNumber: string = '';
  @Input() time: string = '';
  @Input() title: string = '';
  @Input() currency: string = '';
  @Input() totalAmount: number = 0;
  @Input() paidAmount: number = 0;

  constructor(public language: LanguageService) {}

}
