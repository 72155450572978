<ng-container class="m-auto tw-w-11/12 mx-auto">
  <div [matBadge]="notification.repetition_count" matBadgeColor="warn">
    <div
      class="tw-flex tw-shadow-lg tw-bg-graylight py-2 notification-container"
      [ngClass]="{
        'tw-flex-col tw-items-center': isGridView,
        'tw-items-start tw-px-5  justify-content-between': !isGridView,
        'swiped-left': isNotificationSwiped(notification.id) && !language.isArabic,
        'swiped-right': !isNotificationSwiped(notification.id) && !language.isArabic,
        'swiped-left-ar': isNotificationSwiped(notification.id) && language.isArabic,
        'swiped-right-ar': !isNotificationSwiped(notification.id) && language.isArabic
      }"
      (swipeleft)="handleSwipe('left', notification)"
      (swiperight)="handleSwipe('right', notification)">
      <button
        (click)="notificationClick.handleNotificationClick(notification)"
        class="btn tw-p-0"
        [ngClass]="{ 'col-4': !isGridView }">
        <div *ngIf="isGridView">
          <app-card
            [title]="notification.data.title"
            [status]="notification.status.key"
            [tableNumber]="notification.data.table_number">
          </app-card>
          <p class="tw-text-appgray mb-2">{{ formatLocalDate(notification.created_at).fromNow() }}</p>
        </div>
        <div *ngIf="!isGridView" class="d-flex justify-content-between align-items-center">
          <div class="col-4">
            <div
              class="tw-size-10 mx-auto main-background-color d-flex justify-content-center align-items-center rounded-4">
              <h5 class="text-white p-0 m-0">{{ notification.data.table_number }}</h5>
            </div>
            <p class="tw-text-appgray">{{ formatLocalDate(notification.created_at).fromNow() }}</p>
          </div>
          <h5 class="col-8">{{ notification.data.title }}</h5>
        </div>
      </button>
      <span
        class="tw-px-5 tw-text-wrap my-auto"
        [ngClass]="{
          '-tw-translate-y-3 tw-text-[10px]': isGridView,
          'col-4': !isGridView && !isAssigned,
          'col-6 d-flex': !isGridView && isAssigned
        }">
        <span>
          {{ notification.data.body }}
        </span>
        <br *ngIf="!isGridView" />
        <span class="text-center" [ngClass]="{ 'col-6': !isGridView }" *ngIf="isAssigned && staff$ | async as staff">
          {{ 'Assigned To' | translate }}: {{ staff[notification.assigned_to]?.user?.name }}
        </span>
      </span>

      <div class="tw-flex tw-justify-center tw-gap-2 tw-flex-wrap my-auto">
        @if(isPending) {
        <button class="btn tw-rounded-lg tw-bg-secondary tw-text-white" (click)="takeIt(notification)">
          {{ 'Take it' | translate }}
        </button>
        <button
          *ngIf="isCurrentUserAdmin"
          class="btn tw-rounded-lg tw-bg-secondary tw-text-white"
          (click)="openAssigner(notification)">
          {{ 'Assign' | translate }}
        </button>
        } @else if(isAssigned) {
        <button
          *ngIf="isCurrentUserAdmin"
          class="btn tw-rounded-lg tw-bg-secondary tw-text-white"
          (click)="openAssigner(notification)">
          {{ 'Reassign' | translate }}
        </button>
        }

        <div *ngIf="isGridView">
          <button
            class="btn tw-rounded-lg tw-bg-primary tw-text-white"
            *ngIf="unlockRestaurant(notification)"
            (click)="unlockRestaurantTable(notification)">
            {{ 'Unlock table' | translate }}
          </button>
          <button
            class="btn tw-rounded-lg tw-bg-primary tw-text-white"
            *ngIf="isNewOrder(notification)"
            (click)="changeOrderStatus(notification)">
            {{ 'Change status' | translate }}
          </button>
          <button
            class="btn tw-rounded-lg tw-bg-primary tw-text-white"
            *ngIf="isCashPay(notification)"
            (click)="changePaymentCashStatusToPaid(notification)">
            {{ 'paid' | translate }}
          </button>
          <button
            class="btn tw-rounded-lg tw-bg-primary tw-text-white"
            *ngIf="isGridView"
            (click)="takeItAndMarkAsDone(notification)">
            {{ 'Done' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div
      *ngIf="isNotificationSwiped(notification.id)"
      class="d-flex justify-content-between align-items-center"
      [ngClass]="{
        'mark-as-done tw-rounded-r-lg': !language.isArabic,
        'mark-as-done-ar tw-rounded-l-lg': language.isArabic
      }">
      <div
        class="col-6 h-100"
        *ngIf="unlockRestaurant(notification) || isNewOrder(notification) || isCashPay(notification)">
        <button
          class="btn tw-bg-primary tw-text-white text-center px-2 text-center w-100 h-100 rounded-0 m-0"
          *ngIf="unlockRestaurant(notification)"
          (click)="unlockRestaurantTable(notification)">
          {{ 'Unlock table' | translate }}
        </button>
        <button
          class="btn tw-bg-primary tw-text-white w-100 h-100 px-2 text-center rounded-0 m-0"
          *ngIf="isNewOrder(notification)"
          (click)="changeOrderStatus(notification)">
          {{ 'Change status' | translate }}
        </button>
        <button
          class="btn tw-bg-primary tw-text-white w-100 h-100 px-2 text-center rounded-0 m-0"
          *ngIf="isCashPay(notification)"
          (click)="changePaymentCashStatusToPaid(notification)">
          {{ 'paid' | translate }}
        </button>
      </div>
      <div
        class="text-center h-100"
        [ngClass]="{
          'col-6': unlockRestaurant(notification) || isNewOrder(notification) || isCashPay(notification),
          col: !(unlockRestaurant(notification) || isNewOrder(notification) || isCashPay(notification))
        }">
        <button
          class="btn rounded-0 tw-text-white text-center tw-bg-pink w-100 tw-h-full m-0"
          (click)="takeItAndMarkAsDone(notification)">
          {{ 'Done' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
