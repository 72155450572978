import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ResponseModel } from '@models/response.model';
import { AssociatedCompaniesModel } from '@shared/models/associated_companies.model';
import { RestaurantModel } from '@shared/models/restaurant.model';

@Injectable({ providedIn: 'root' })
export class RestaurantService {
  private update = new Subject<string>();
  company: AssociatedCompaniesModel = JSON.parse(localStorage.getItem('company'));
  company_id: string = this.company?.id;
  restaurants: RestaurantModel[] = this.company?.restaurants;
  constructor(
    private http: HttpClient,
  ) {}

  getAll(
    page = 0,
    perPage = 5,
    sort: string | null = null,
    filterKeyword: string | null = null,
    filterStatus: string | null = null,
    role: string | null = null
  ) {
    let params = new HttpParams().set('page', page).set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }

    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }

    if (filterStatus !== null) {
      params = params.set('filter[status]', filterStatus);
    }

    return this.http.get<ResponseModel<RestaurantModel[]>>('companies/' + this.company_id + '/restaurants', { params });
  }

  addRestaurant(restaurant: object) {
    return this.http.post<ResponseModel<RestaurantModel>>('companies/' + this.company_id + '/restaurants', restaurant);
  }

  getRestaurantByID(id: string) {
    return this.http.get<ResponseModel<RestaurantModel>>(`companies/${this.company_id}/restaurants/${id}`);
  }

  editRestaurant(restaurantId: string, restaurant: object) {
    return this.http.post(
      `companies/${this.company_id}/restaurants/${restaurantId}?_method=PUT`,
      restaurant
    );
  }

  deleteRestaurant(id: string) {
    return this.http.delete(`companies/${this.company_id}/restaurants/${id}`);
  }

  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }
}
