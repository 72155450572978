<div class="dropdown">
  <button class="btn border-0 m-0 p-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    <div class="tw-size-12 main-background-color d-flex justify-content-center align-items-center rounded-4">
      <h5 class="text-white p-0 m-0">
        <img class="sort-image" src="assets/images/sortVector.png" alt="Sort" />
      </h5>
    </div>
  </button>
  <ul class="dropdown-menu" [ngClass]="{'rtl' : language.isArabic}">
    <li *ngFor="let item of dropdownItem">
      <a class="dropdown-item" (click)="onStatusSelect(item.value)">
        {{ item.title | translate }}
      </a>
    </li>
  </ul>
</div>
