<div class="tw-w-full">
  <swiper-container #swiperRef class="px-0 mt-2 tw-sticky tw-top-0 swipercontainer">
    <swiper-slide
      *ngFor="let category of menuCategories; let i = index"
      class="px-0 text-center"
      (click)="onSlideClick(i, category.title)">
      <div class="slidecontainer" [class.active]="sliderActive === i">
        <div class="my-2 clicableitem">
          <p class="tab-title py-2 rounded-2 bg-white tw-text-sm">
            <span *ngIf="!language.isArabic">{{ category.title }}</span>
            <span *ngIf="language.isArabic">{{ category.title_ar }}</span>
          </p>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  <div>
    <div class="tw-relative" *ngFor="let categoryItem of menuCategories; let i = index">
      <span
        appScrollObserver
        (elementIsInView)="onElementInView(i)"
        #categoryRefs
        [id]="categoryItem.title"
        class="tw-absolute tw--top-[100px]"></span>
      <h5 class="categorytitle my-2 tw-text-lg">{{ categoryItem.title }}</h5>
      <div *ngFor="let item of categoryItem.menu_items">
          <app-item-card [item]="item" />
      </div>
    </div>
  </div>
</div>
