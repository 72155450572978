import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { TokenModel } from '@models/token.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { LocalStorageRefService } from './local-storage-ref.service';
import { ResponseModel } from '@models/response.model';
import { StorageKeysEnum } from '../enum/storage-keys.enum';
import { UserModel } from '@models/user.model';
import { PermissionModel } from '@models/permission.model';
import { AssociatedCompaniesModel } from '@shared/models/associated_companies.model';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { BranchModel } from '@shared/models/branch.model';
import { FCMService } from '@services/fcm.service';
import { v4 as uuidv4 } from 'uuid';
import { StaffModel } from '@shared/models/staff.model';
@Injectable({ providedIn: 'root' })
export class AuthService {
  loginUpdate = new Subject<any>();
  token$: BehaviorSubject<TokenModel | null> = new BehaviorSubject<TokenModel | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private fcmService: FCMService,
    private permissionsService: NgxPermissionsService,
    private localStorageRefService: LocalStorageRefService
  ) {
    this.token$.next(this.loadFromStorage());
  }

  public get currentUser() {
    return this.token$.value?.profile || null;
  }

  login(phone: string, password: string) {
    const data: {
      phone: string;
      password: string;
      udid?: string;
      fcm_token?: string;
    } = {
      phone: phone,
      password: password,
    };
    const fcmToken = this.fcmService.getFcmToken();
    if (fcmToken) {
      const uuid = uuidv4();
      data['udid'] = uuid;
      data['fcm_token'] = fcmToken;
    }
    return this.http.post<ResponseModel<TokenModel>>('auth/login', data);
  }

  request_forget_password(data) {
    return this.http.post('auth/request-forget-password', data);
   }

   Forget_password(data) {
    return this.http.post('auth/forget-password', data);
  }

  requestOtpCode(data) {
    return this.http.post('auth/request-phone-otp', data);
  }

  refreshLoginData(profile) {
    const company = profile.associated_companies[0];
    const userType = localStorage.getItem('userType');
    this.saveCompany(company, userType)
    const restaurant = JSON.parse(localStorage.getItem('restaurant'));
    const restaurantId: string = restaurant.id;
    let restaurantMatched = false;

    for (const restaurant of company.restaurants) {
      if (restaurant.id === restaurantId) {
        this.saveRestaurant(restaurant);
        const branch: BranchModel = JSON.parse(localStorage.getItem('branch')!);
        const branchId: string = branch.id;
        const matchFound = restaurant.branches.some(restaurantBranch => branchId === restaurantBranch.id);
        if (matchFound) {
          return; // If a branch match is found, return and stop further execution
        } else {
          const branch = restaurant.branches[0]; // Otherwise, save the first branch
          this.saveBranch(branch);
        }
        restaurantMatched = true; // Mark that a matching restaurant was found
        break; // Exit the loop once we find the matching restaurant
      }
    }
    // If no restaurant matched the restaurantId, save the first restaurant
    if (!restaurantMatched) {
      this.saveRestaurant(company.restaurants[0]);
      this.saveBranch(company.restaurants[0].branches[0])
    }
  }

  logout() {
    const user = this.token$.value;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${user?.access_token}`,
    });
    this.http
      .get<ResponseModel<TokenModel>>('auth/logout', {
        headers: headers,
      })
      .subscribe();
    this.unAuth().then();
  }

  async unAuth() {
    this.localStorageRefService.removeData(StorageKeysEnum.USER);
    this.localStorageRefService.removeData(StorageKeysEnum.FCM_TOKEN);
    this.localStorageRefService.removeData(StorageKeysEnum.COMPANY);
    this.localStorageRefService.removeData(StorageKeysEnum.RESTAURANT);
    this.localStorageRefService.removeData(StorageKeysEnum.BRANCH);
    this.localStorageRefService.removeData(StorageKeysEnum.STAFF);
    localStorage.removeItem('userType');
    this.token$.next(null);
    await this.router.navigate(['/auth/login']);
  }

  isLoggedIn() {
    return this.token$.value !== null;
  }

  addAuthHeader(request: HttpRequest<any>, user: TokenModel): any {
    if (user && user.access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.access_token}`,
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
    }

    return request;
  }

  requestOtp(id: any) {
    return this.http.post<ResponseModel<any>>('admin/users/' + id.toString() + '/request-otp', {});
  }

  verifyEmail(code: string) {
    const body = {
      otp_code: code,
    };
    return this.http.post<ResponseModel<TokenModel>>('auth/verify-otp/email', body).pipe(
      tap(async () => {
        // this.handleAuthentication(response.data);
      })
    );
  }

  sendLoginUpdate(message: string) {
    this.loginUpdate.next(message);
  }

  getLoginUpdate() {
    return this.loginUpdate.asObservable();
  }

  loadPermissions(permissions: PermissionModel[]) {
    this.permissionsService.loadPermissions(permissions.map(permission => permission.name));
  }

  private loadFromStorage() {
    return JSON.parse(this.localStorageRefService.getData(StorageKeysEnum.USER)) as TokenModel;
  }

  handleAuthentication(token: TokenModel, usertype: string) {
    this.token$.next(token);
    this.localStorageRefService.setData(StorageKeysEnum.USER, JSON.stringify(token));
    if (usertype == 'admin') {
      this.loadPermissions(token.profile?.permissions || []);
    }
  }

  saveCompany(company: AssociatedCompaniesModel, usertype: string) {
    this.localStorageRefService.setData(StorageKeysEnum.COMPANY, JSON.stringify(company));
    if (usertype == 'admin') {
      if (company.restaurants && company.restaurants[0]) {
        this.saveRestaurant(company.restaurants[0]);
        if (company.restaurants[0].branches && company.restaurants[0].branches[0]) {
          this.saveBranch(company.restaurants[0].branches[0]);
        }
      }
    }
  }

  saveBranch(branch: BranchModel) {
    this.localStorageRefService.setData(StorageKeysEnum.BRANCH, JSON.stringify(branch));
  }

  saveRestaurant(restaurant: RestaurantModel) {
    this.localStorageRefService.setData(StorageKeysEnum.RESTAURANT, JSON.stringify(restaurant));
  }

  getStaff() {
    const userprofile = this.loadUserFromStorage();
    const staff = userprofile.staff;
    return staff;
  }
  saveStaff(staff: StaffModel) {
    this.localStorageRefService.setData(StorageKeysEnum.STAFF, JSON.stringify(staff));
    this.loadPermissions(staff.role.permissions || []);
    this.saveCompany(staff.company, 'staff');
    this.saveBranch(staff.branch);
    this.saveRestaurant(staff.restaurant);
  }
  handleAuthenticationAfterEditRoles(userModel: UserModel) {
    const tokenModel: TokenModel = new BehaviorSubject<TokenModel>(
      JSON.parse(localStorage.getItem(StorageKeysEnum.USER)!)
    ).value as TokenModel;

    tokenModel.profile = userModel;

    this.token$.next(tokenModel);
    this.localStorageRefService.setData(StorageKeysEnum.USER, JSON.stringify(tokenModel));
    const userType = localStorage.getItem('userType');
    if (userType == 'staff') {
      const staff: StaffModel = JSON.parse(localStorage.getItem('staff'));
      this.loadPermissions(staff.role.permissions || []);
    } 
      this.loadPermissions(userModel?.permissions || []);
  }

  loadUserFromStorage(): UserModel | null {
    const userJson = this.localStorageRefService.getData(StorageKeysEnum.USER) ?? null;
    if (userJson != null && userJson != 'undefined' && userJson != undefined) {
      const tokenModel = JSON.parse(userJson) as TokenModel;
      return tokenModel.profile;
    }
    return null;
  }
}
