<nb-card class="page-wrapper">
  <nb-card-header class="page-header bg-white d-flex flex-column">
    <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
      <i class="bi bi-x-square-fill m-0"></i>
    </button>
    <p *ngIf="isAddSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{ 'Add Payfort Settings' | translate }}
    </p>
    <p *ngIf="isEditSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{ 'Update Payfort Settings' | translate }}
    </p>
  </nb-card-header>
  <nb-card-body class="page-body">
    <form
      action="javascript:"
      class="reset h-100"
      [formGroup]="payfortForm"
      [ngClass]="{ 'was-validated': submitted, rtl: language.isArabic }"
      class="table-wrapper p-4">
      <div class="h-100 d-flex flex-column justify-content-around">
        <div>
          <!-- base_url -->
          <div class="my-2">
            <label class="form-label">{{ 'Base url' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['base_url'].errors }"
              formControlName="base_url"
              placeholder="{{ 'Please enter Base url' | translate }}" />
          </div>

          <!-- is_sandbox -->
          <div class="my-2">
            <label class="form-label">{{ 'Sandbox' | translate }}</label>
            <select
              class="form-select input-control"
              aria-label="Default select example"
              formControlName="is_sandbox"
              required
              [ngClass]="{ 'is-invalid': submitted && f['is_sandbox'].errors }">
              <option [value]="0">
                {{ 'No' | translate }}
              </option>
              <option [value]="1">
                {{ 'Yes' | translate }}
              </option>
            </select>
          </div>

          <!-- language -->
          <div class="my-2">
            <label class="form-label">{{ 'Language' | translate }}</label>
            <select
              class="form-select input-control"
              aria-label="Default select example"
              formControlName="language"
              required
              [ngClass]="{ 'is-invalid': submitted && f['language'].errors }">
              <option *ngFor="let status of languageEnum; index as i" [value]="status.value">
                {{ status.title | translate }}
              </option>
            </select>
          </div>

          <!-- merchant_identifier -->
          <div class="my-2">
            <label class="form-label">{{ 'Merchant Identifier' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['merchant_identifier'].errors }"
              formControlName="merchant_identifier"
              placeholder="{{ 'Please enter merchant identifier' | translate }}" />
          </div>

          <!-- access_code -->
          <div class="my-2">
            <label class="form-label">{{ 'Access code' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['access_code'].errors }"
              formControlName="access_code"
              placeholder="{{ 'Please enter access code' | translate }}" />
          </div>

          <!-- sha_type -->
          <div class="my-2">
            <label class="form-label">{{ 'Sha type' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['sha_type'].errors }"
              formControlName="sha_type"
              placeholder="{{ 'Please enter sha type' | translate }}" />
          </div>

          <!-- sha_request_phrase -->
          <div class="my-2">
            <label class="form-label">{{ 'Sha request phrase' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['sha_request_phrase'].errors }"
              formControlName="sha_request_phrase"
              placeholder="{{ 'Please enter sha request phrase' | translate }}" />
          </div>

          <!-- sha_response_phrase -->
          <div class="my-2">
            <label class="form-label">{{ 'Sha response phrase' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['sha_response_phrase'].errors }"
              formControlName="sha_response_phrase"
              placeholder="{{ 'Please enter sha response phrase' | translate }}" />
          </div>

          <!-- currency -->
          <div class="my-2">
            <label class="form-label">{{ 'Currency' | translate }}</label>
            <select
              class="form-select input-control"
              aria-label="Default select example"
              formControlName="currency"
              required
              [ngClass]="{ 'is-invalid': submitted && f['currency'].errors }">
              <option *ngFor="let status of currancyEnum; index as i" [value]="status.value">
                {{ status.title | translate }}
              </option>
            </select>
          </div>

          <!-- return_url -->
          <div class="my-2">
            <label class="form-label">{{ 'Return url' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['return_url'].errors }"
              formControlName="return_url"
              placeholder="{{ 'Please enter return url' | translate }}" />
          </div>

          <!-- return_url_tokenization -->
          <div class="my-2">
            <label class="form-label">{{ 'Return url tokenization' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['return_url_tokenization'].errors }"
              formControlName="return_url_tokenization"
              placeholder="{{ 'Please enter return url tokenization' | translate }}" />
          </div>

          <!-- exception_return_type -->
          <div class="my-2">
            <label class="form-label">{{ 'Exception return type' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['exception_return_type'].errors }"
              formControlName="exception_return_type"
              placeholder="{{ 'Please enter exception return type' | translate }}" />
          </div>

          <!-- path-->
          <div class="my-2">
            <label class="form-label">{{ 'Path' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['path'].errors }"
              formControlName="path"
              placeholder="{{ 'Please enter path' | translate }}" />
          </div>
        </div>

        <!-- is_sandbox -->
        <div class="my-2">
          <label class="form-label">{{ 'Is Active' | translate }}</label>
          <select
            class="form-select input-control"
            aria-label="Default select example"
            formControlName="is_active"
            required
            [ngClass]="{ 'is-invalid': submitted && f['is_active'].errors }">
            <option [value]="0">
              {{ 'No' | translate }}
            </option>
            <option [value]="1">
              {{ 'Yes' | translate }}
            </option>
          </select>
        </div>

        <!-- button -->
        <div>
          <button
            (click)="submit()"
            type="button"
            class="btn text-white tw-text-base tw-font-medium w-100 py-3 secondary-background-color tw-rounded-2xl">
            <p *ngIf="isAddSettings && permissionStore">{{ 'Create Payfort Settings' | translate }}</p>
            <p *ngIf="isEditSettings && permissionUpdate">{{ 'Update Payfort Settings' | translate }}</p>
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
