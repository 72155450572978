import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NbComponentStatus } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { ChangeOrderStatusDialogComponent } from '@pages/notifications-page/change-order-status-dialog/change-order-status-dialog.component';
import { ChangePaymentCashStatusToPaidDialogComponent } from '@pages/notifications-page/change-payment-cash-status-to-paid-dialog/change-payment-cash-status-to-paid-dialog.component';
import { NotificationAssignerDialogComponent } from '@pages/notifications-page/notification-assigner-dialog/notification-assigner-dialog.component';
import { AccessLevelEnum } from '@shared/enum/accessLevel.enum';
import { NotificationTypeEnum } from '@shared/enum/notification-type.enum';
import { TableStatusEnum } from '@shared/enum/table-status.enum';
import { NotificationsModel } from '@shared/models/notifications.model';
import { AuthService } from '@shared/services/auth.service';
import { HandleNotificationClickService } from '@shared/services/handle-notification-click.service';
import { LanguageService } from '@shared/services/language.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { StaffService } from '@shared/services/staff/staff.service';
import { TableService } from '@shared/services/table/table.service';
import { ToastService } from '@shared/services/toast.service';
import moment from 'moment';
import { first, map, shareReplay, switchMap, take } from 'rxjs';

@Component({
  selector: 'app-notifications-card',
  templateUrl: './notifications-card.component.html',
  styleUrls: ['./notifications-card.component.css'],
})
export class NotificationsCardComponent {
  @Input() notification: NotificationsModel;
  @Input() isGridView: boolean;
  @Input() notificationsType: string = '';

  get isPending() {
    return this.notificationsType === 'pending'
  }

  get isAssigned() {
    return this.notificationsType === 'assigned'
  }
  swipedNotification: string | null = null;
  swipedNotifications: Set<string> = new Set();

  get isCurrentUserAdmin() {
    return (
      this.authService.currentUser.staff.find(st => st.user_id === this.authService.currentUser.id)?.access_level
        .key === AccessLevelEnum.BRANCH_ADMIN
    );
  }
  staff$ = this.staffService
    .getAll({
      page: 1,
      perPage: 100,
      sort: null,
      filterKeyword: null,
      filterStatus: null,
      role: null,
    })
    .pipe(
      map(res => res.data),
      map(res => res.reduce((obj, item) => ({ ...obj, [item.id]: item }), {})),
      shareReplay(1)
    );

  constructor(
    private notificationService: NotificationsService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private staffService: StaffService,
    private tableService: TableService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public notificationClick: HandleNotificationClickService,
    public language: LanguageService
  ) {}

  getTableStatus(status: string) {
    return TableStatusEnum[status];
  }

  takeIt(notification) {
    this.notificationService
      .assign_to_self(notification.id)
      .pipe(
        switchMap(() => this.notificationService.notification_as_read(notification.id)),
        take(1)
      )
      .subscribe(() => this.notificationService.refresh());
  }

  takeItAndMarkAsDone(notification) {
    this.notificationService
      .assign_to_self(notification.id)
      .pipe(
        switchMap(() => this.notificationService.notification_as_read(notification.id)),
        take(1)
      )
      .subscribe(() => this.markAsDone(notification.id));
  }

  openAssigner(notification) {
    const ref = this.matDialog.open(NotificationAssignerDialogComponent, {
      width: '500px',
      data: {
        notification: notification,
      },
    });
    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.notificationService.refresh());
  }

  markAsDone(notificationId: string) {
    this.notificationService
      .mark_as_done(notificationId)
      .pipe(take(1))
      .subscribe(() => this.notificationService.refresh());
  }

  showNotification(title: string, message: string, type: NbComponentStatus) {
    this.toastService.showTranslatedToast(type, title, message);
  }

  unlockRestaurant(notification) {
    if (notification.data.type === (NotificationTypeEnum.UNLOCK_RESTAURANT_TABLE as number)) {
      return true;
    } else {
      return false;
    }
  }

  unlockRestaurantTable(notification) {
    const data = {
      status: 1,
    };
    this.tableService
      .editTable(notification.data.table_id, data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.showNotification('Table', 'Unlock table successfully', 'success');
          this.notificationService
            .assign_to_self(notification.id)
            .pipe(
              switchMap(() => this.notificationService.notification_as_read(notification.id)),
              take(1)
            )
            .subscribe(() => this.markAsDone(notification.id));
        },
        error: error => {
          this.showNotification(error.message, error.errors.toString(), 'danger');
        },
      });
  }

  isNewOrder(notification) {
    if (notification.data.type === (NotificationTypeEnum.NEW_ORDER as number)) {
      return true;
    } else {
      return false;
    }
  }

  isCashPay(notification) {
    if (notification.data.type === (NotificationTypeEnum.NOTIFY_WAITER_TO_PAY_CASH as number)) {
      return true;
    } else {
      return false;
    }
  }

  changePaymentCashStatusToPaid(notification) {
    const ref = this.matDialog.open(ChangePaymentCashStatusToPaidDialogComponent, {
      width: '500px',
      data: {
        notification: notification,
        paymentId: notification.data.payment_id,
      },
    });
    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.notificationService.refresh());
  }

  changeOrderStatus(notification) {
    const ref = this.matDialog.open(ChangeOrderStatusDialogComponent, {
      width: '500px',
      data: {
        notification: notification,
        orderId: notification.data.order_id,
      },
    });
    ref
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.notificationService.refresh());
  }
  // swipe
  onSwipeLeft(notification) {
    this.swipedNotifications.add(notification.id); // Add to swiped state
    this.cdr.detectChanges(); // Trigger change detection
  }

  onSwipeRight(notification) {
    this.swipedNotifications.delete(notification.id); // Remove from swiped state
    this.cdr.detectChanges(); // Trigger change detection
  }

  handleSwipe(direction: 'left' | 'right', notification): void {
    if (!this.isGridView && !this.language.isArabic) {
      if (direction === 'left') {
        this.onSwipeLeft(notification);
      } else if (direction === 'right') {
        this.onSwipeRight(notification);
      }
    } else if (!this.isGridView && this.language.isArabic) {
      if (direction === 'left') {
        this.onSwipeRight(notification);
      } else if (direction === 'right') {
        this.onSwipeLeft(notification);
      }
    }
  }

  formatLocalDate(dateString: string) {
    const language = this.translateService.currentLang;
    const utcMoment = moment.utc(dateString);
    const localMoment = utcMoment.local();
    localMoment.locale(language);
    return localMoment;
  }

  isNotificationSwiped(notificationId: string): boolean {
    return this.swipedNotifications.has(notificationId);
  }
}
