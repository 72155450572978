<nb-card class="mb-0 p-4 pt-2 pb-0">
    <nb-card-body class="p-0">
        <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
            <i class="bi bi-x-square-fill m-0"></i>
        </button>
        <div class="row my-2 m-0">
            <div class="col-md-12 px-1">
                <label class="form-label">
                    {{'Change order status' | translate}}
                </label>
                <select
                class="form-select tw-h-14 main-color tw-text-lg tw-font-medium"
                aria-label="Default select example"
                [(ngModel)]="orderStatus"
                required>
                <option *ngFor="let status of orderStatusEnum; index as i" value="{{ status.value }}">
                  {{ status.title | translate }}
                </option>
              </select>
            </div>
        </div>
        <div class="row my-2 m-0">
            <button
            [disabled]="!orderStatus"
            (click)="updateOrder()"
            class="
            btn b-0 m-0 border-0 tw-bg-primary tw-text-white
            disabled:tw-bg-gray-300 disabled:tw-text-gray-500
            ">{{'Update status' | translate}}</button>
        </div>
    </nb-card-body>
</nb-card>