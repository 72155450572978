import { Component, Input } from '@angular/core';
import { LanguageService } from '@shared/services/language.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent {
  @Input() status: number = 0;
  @Input() tableNumber: string = '';
  @Input() title: string = '';
  constructor(public language: LanguageService) {}
}
