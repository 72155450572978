import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const translateService = inject(TranslateService);
      const language = translateService.currentLang;
      const BASE_PATH = req.url;
      if (!BASE_PATH.endsWith('.json')) {
        req = req.clone({
          url: BASE_PATH,
          setHeaders: {
            'Accept-Language': language,
          },
        });
      }
      return next.handle(req);
  }
}
