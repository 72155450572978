import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {NotificationsModel} from "@models/notifications.model";
import {ResponseModel} from "@models/response.model";
import { BehaviorSubject, combineLatest, Observable, shareReplay, startWith, switchMap } from 'rxjs';
import { FCMService } from './fcm.service';


@Injectable({
  providedIn: 'root',
})

export class NotificationsService {

  private refresher$ = new BehaviorSubject(null);

  notifications$ = combineLatest([
     this.refresher$,
     this.fcmService.getNotificationObservable().pipe(startWith(null))
    ]).pipe(
    switchMap(() => this.show_all_notifications()),
    shareReplay(1)
  )

  constructor(
    private http:HttpClient,
    private route:Router,
    private fcmService:FCMService,
  
  ) { }
  refresh(){
    this.refresher$.next(null)
  }
  show_all_notifications(page = 1, perPage = 10): Observable<ResponseModel<NotificationsModel[]>> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<ResponseModel<NotificationsModel[]>>('auth/notifications', { params });
  }


  mark_all_as_read() {
      return this.http.post('auth/notifications/mark-all-as-read?_method=PUT', null);
  }
  notification_as_read(id:string) {
    return this.http.post(`auth/notifications/${id}/mark-as-read?_method=PUT`, null);
  }


  assign_to_self(notificationId:string) {
    return this.http.post(`auth/notifications/${notificationId}/assign-to-self`, {});
  }
  assign_to_waiter({notificationId, staffId}:{notificationId:string, staffId:string}) {
    return this.http.post(`auth/notifications/${notificationId}/assign-to-waiter`, {
      staff_id:staffId
    });
  }
  
  mark_as_done(notificationId:string) {
    return this.http.put(`auth/notifications/${notificationId}/mark-as-done`, {});
    
  }




}
