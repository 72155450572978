export enum PermissionsEnum {
  INDEX_USER = 'INDEX_USER',
  SHOW_USER = 'SHOW_USER',
  STORE_USER = 'STORE_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  SHOW_ROLE = 'SHOW_ROLE',
  STORE_ROLE = 'STORE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',
  UPDATE_ROLE = 'UPDATE_ROLE',
  INDEX_ROLE = 'INDEX_ROLE',
  EDIT_ROLE_PERMISSION = 'EDIT_ROLE_PERMISSION',
  SHOW_ROLE_PERMISSION = 'SHOW_ROLE_PERMISSION',
  SHOW_USER_ROLE = 'SHOW_USER_ROLE',
  EDIT_USER_ROLE = 'EDIT_USER_ROLE',
  SHOW_PERMISSIONS = 'SHOW_PERMISSIONS',
  INDEX_RESTAURANT = 'INDEX_RESTAURANT',
  UPDATE_RESTAURANT = 'UPDATE_RESTAURANT',
  DELETE_RESTAURANT = 'DELETE_RESTAURANT',
  SHOW_RESTAURANT = 'SHOW_RESTAURANT',
  STORE_RESTAURANT = 'STORE_RESTAURANT',
  INDEX_SERVICE = 'INDEX_SERVICE',
  SHOW_SERVICE = 'SHOW_SERVICE',
  STORE_SERVICE = 'STORE_SERVICE',
  DELETE_SERVICE = 'DELETE_SERVICE',
  UPDATE_SERVICE = 'UPDATE_SERVICE',
  INDEX_PATIENT = 'INDEX_PATIENT',
  SHOW_PATIENT = 'SHOW_PATIENT',
  STORE_PATIENT = 'STORE_PATIENT',
  DELETE_PATIENT = 'DELETE_PATIENT',
  UPDATE_PATIENT = 'UPDATE_PATIENT',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  INDEX_CATEGORY = 'INDEX_CATEGORY',
  INDEX_ALL_CATEGORIES = 'INDEX_ALL_CATEGORIES',
  SHOW_CATEGORY = 'SHOW_CATEGORY',
  STORE_CATEGORY = 'STORE_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  INDEX_APPOINTMENT = 'INDEX_APPOINTMENT',
  SHOW_APPOINTMENT = 'SHOW_APPOINTMENT',
  GENERATE_CONSENT_FORM = 'GENERATE_CONSENT_FORM',
  GENERATE_REFUSAL_FORM = 'GENERATE_REFUSAL_FORM',
  GENERATE_MEDICAL_RECORD_FORM = 'GENERATE_MEDICAL_RECORD_FORM',
  SEND_USER_EMAIL_OTP = 'SEND_USER_EMAIL_OTP',
  STORE_APPOINTMENT = 'STORE_APPOINTMENT',
  STORE_APPOINTMENT_DOCUMENT = 'STORE_APPOINTMENT_DOCUMENT',
  STORE_PATIENT_DOCUMENT = 'STORE_PATIENT_DOCUMENT',
  UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT',
  DELETE_APPOINTMENT = 'DELETE_APPOINTMENT',
  DELETE_APPOINTMENT_DOCUMENT = 'DELETE_APPOINTMENT_DOCUMENT',
  DELETE_PATIENT_DOCUMENT = 'DELETE_PATIENT_DOCUMENT',
  UPDATE_APPOINTMENT_ITEM = 'UPDATE_APPOINTMENT_ITEM',
  DELETE_APPOINTMENT_ITEM = 'DELETE_APPOINTMENT_ITEM',
  INDEX_BRANCH = 'INDEX_BRANCH',
  SHOW_BRANCH = 'SHOW_BRANCH',
  STORE_BRANCH = 'STORE_BRANCH',
  UPDATE_BRANCH = 'UPDATE_BRANCH',
  DELETE_BRANCH = 'DELETE_BRANCH',
  INDEX_STAFF = 'INDEX_STAFF',
  SHOW_STAFF = 'SHOW_STAFF',
  STORE_STAFF = 'STORE_STAFF',
  UPDATE_STAFF = 'UPDATE_STAFF',
  DELETE_STAFF = 'DELETE_STAFF',
  INDEX_MENU = 'INDEX_MENU',
  SHOW_MENU = 'SHOW_MENU',
  STORE_MENU = 'STORE_MENU',
  UPDATE_MENU = 'UPDATE_MENU',
  DELETE_MENU = 'DELETE_MENU',
  INDEX_RESTAURANT_TABLE = 'INDEX_RESTAURANT_TABLE',
  SHOW_RESTAURANT_TABLE = 'SHOW_RESTAURANT_TABLE',
  STORE_RESTAURANT_TABLE = 'STORE_RESTAURANT_TABLE',
  UPDATE_RESTAURANT_TABLE = 'UPDATE_RESTAURANT_TABLE',
  DELETE_RESTAURANT_TABLE = 'DELETE_RESTAURANT_TABLE',
  INDEX_MENU_CATEGORY ='INDEX_MENU_CATEGORY',
  SHOW_MENU_CATEGORY = 'SHOW_MENU_CATEGORY',
  STORE_MENU_CATEGORY = 'STORE_MENU_CATEGORY',
  UPDATE_MENU_CATEGORY = 'UPDATE_MENU_CATEGORY',
  DELETE_MENU_CATEGORY = 'DELETE_MENU_CATEGORY',
  INDEX_MENU_ITEM = 'INDEX_MENU_ITEM',
  SHOW_MENU_ITEM = 'SHOW_MENU_ITEM',
  STORE_MENU_ITEM = 'STORE_MENU_ITEM',
  UPDATE_MENU_ITEM = 'UPDATE_MENU_ITEM',
  DELETE_MENU_ITEM = 'DELETE_MENU_ITEM',
  INDEX_ORDER = 'INDEX_ORDER',
  SHOW_ORDER = 'SHOW_ORDER',
  STORE_ORDER = 'STORE_ORDER',
  DELETE_ORDER = 'DELETE_ORDER',
  UPDATE_ORDER = 'UPDATE_ORDER',
  GENERATE_QR_CODE = 'GENERATE_QR_CODE',
  UPDATE_FAILED_PAYMENT = 'UPDATE_FAILED_PAYMENT',
  UPDATE_DONE_PAYMENT = 'UPDATE_DONE_PAYMENT',
  STORE_PAYMENT= 'STORE_PAYMENT',
  SHOW_PAYMENT = 'SHOW_PAYMENT',
  UPDATE_PAYMENT = 'UPDATE_PAYMENT',
  DELETE_PAYMENT = 'DELETE_PAYMENT',
  UPDATE_TERMS_AND_CONDITIONS = 'UPDATE_TERMS_AND_CONDITIONS',
  DELETE_REVIEW = 'DELETE_REVIEW',
  GET_BRANCH_SETTING = 'GET_BRANCH_SETTING',
  UPDATE_BRANCH_SETTING = 'UPDATE_BRANCH_SETTING',
  STORE_BRANCH_SETTINGS = 'STORE_BRANCH_SETTINGS'

}
