import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PaymentGatwayEnum } from '@shared/enum/payment-gateway.enum';
import { DialogData } from '@shared/models/dialog-data.model';
import { EnumModel } from '@shared/models/enum.model';
import { HttpErrorModel } from '@shared/models/http-error.model';
import { LanguageService } from '@shared/services/language.service';
import { MethodsUtils } from '@shared/utils/methods.utils';

@Component({
  selector: 'app-payment-gateway-settings-dialog',
  templateUrl: 'payment-gateway-settings-dialog.component.html',
  styleUrls: ['payment-gateway-settings-dialog.component.scss'],
})
export class PaymentGatewaySettingsDialogComponent {
  paymentMeyhodEnum: EnumModel[];
  paymentGatewayForm: UntypedFormGroup;
  httpError: HttpErrorModel | null = null;
  @Output() selectedPaymentMethod = new EventEmitter<string>();
  submitted = false;
  constructor(
    private methodUtils: MethodsUtils,
    public language: LanguageService,
    public dialogRef: MatDialogRef<PaymentGatewaySettingsDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.paymentMeyhodEnum = this.methodUtils.getStatuses(PaymentGatwayEnum);
    this.paymentGatewayForm = this.fb.group({
      payment_method: [null, [Validators.required]],
    });
  }

  
  get f() {
    return this.paymentGatewayForm.controls;
  }
  
  submit() {
    if (this.paymentGatewayForm.valid) {
      const selectedMethod = this.paymentGatewayForm.get('payment_method')?.value;
      this.selectedPaymentMethod.emit(selectedMethod); // Emit selected payment method
      this.dialogRef.close(selectedMethod); // Optionally close the dialog with the selected value
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
