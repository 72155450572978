import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { BranchModel } from '@shared/models/branch.model';
import { ResponseModel } from '@shared/models/response.model';
import { PaymentModel } from '@shared/models/payment.model';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  restaurant: RestaurantModel = JSON.parse(localStorage.getItem('restaurant'));
  restaurantID: string = this.restaurant.id;
  branch: BranchModel = JSON.parse(localStorage.getItem('branch'));
  branchID: string = this.branch.id;
  constructor(private http: HttpClient) {}

  UpdatePaymentStatusToPrepare(paymentId: string) {
    return this.http.post(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/payments/${paymentId}/mark-as-failed?_method=PUT`,
      null
    );
  }

  UpdatePaymentStatusToAccept(paymentId: string) {
    return this.http.post(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/payments/${paymentId}/mark-as-done?_method=PUT`,
      null
    );
  }

  getPaymentByID(paymentId: string) {
    return this.http.get<ResponseModel<PaymentModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/payments/${paymentId}`
    );
  }

  addPayment(payment: object) {
    return this.http.post<ResponseModel<PaymentModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/payments`,
      payment
    );
  }

  editPayment(paymentId: string, payment: object) {
    return this.http.post<ResponseModel<PaymentModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/payments/${paymentId}?_method=PUT`,
      payment
    );
  }

  deletePayment(paymentId: string) {
    return this.http.delete(`restaurants/${this.restaurantID}/branches/${this.branchID}/payments/${paymentId}`);
  }
}
