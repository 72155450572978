import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbComponentStatus } from '@nebular/theme';
import { OrderStatusEnum } from '@shared/enum/order-status.enum';
import { NotificationsModel } from '@shared/models/notifications.model';
import { OrderService } from '@shared/services/order/order.service';
import { ToastService } from '@shared/services/toast.service';
import { MethodsUtils } from '@shared/utils/methods.utils';
import { first } from 'rxjs';

@Component({
  selector: 'app-change-order-status-dialog',
  templateUrl: './change-order-status-dialog.component.html',
  styleUrls: ['./change-order-status-dialog.component.scss'],
})
export class ChangeOrderStatusDialogComponent {
  orderStatusEnum;
  orderId:string='';
  orderStatus: string = '';
  constructor(
    public dialogRef: MatDialogRef<ChangeOrderStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { notification: NotificationsModel, orderId: string  },
    private methodUtils: MethodsUtils,
    private orderService: OrderService,
    private toastService: ToastService
  ) {
    this.orderStatusEnum = this.methodUtils.getStatuses(OrderStatusEnum);
    this.orderId = data.orderId;
  }

  updateOrder() {
    if (!this.orderStatus) {
      return; // Prevent submission if no status is selected
    }
    const orderStus= {
      status: this.orderStatus,
    }
    this.orderService
      .editOrder(this.orderId, orderStus)
      .pipe(first())
      .subscribe({
        next: () => {
          this.closeDialog()
          this.showNotification('Order', 'Order status updated successfully', 'success');
        },
        error: error => {
          this.showNotification(error.message, error.errors.toString(), 'danger');
        },
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  showNotification(title: string, message: string, type: NbComponentStatus) {
    this.toastService.showTranslatedToast(type, title, message);
  }
}
