import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ResponseModel } from '@shared/models/response.model';
import { RestaurantModel } from '@shared/models/restaurant.model';
import { OrderModel } from '@shared/models/order.model';
import { BranchModel } from '@shared/models/branch.model';
import { PaymentModel } from '@shared/models/payment.model';

@Injectable({ providedIn: 'root' })
export class OrderService {
  private update = new Subject<string>();
  restaurant: RestaurantModel = JSON.parse(localStorage.getItem('restaurant'));
  restaurantID: string = this.restaurant.id;
  branch: BranchModel = JSON.parse(localStorage.getItem('branch'));
  branchID: string = this.branch.id;
  constructor(private http: HttpClient) {}

  getAll(
    page = 0,
    perPage = 5,
    sort: string | null = null,
    filterKeyword: string | null = null,
    filterstatus: string,
    role: string | null = null
  ) {
    let params = new HttpParams().set('page', page).set('per_page', perPage);
    if (sort !== null) {
      params = params.set('sort', sort);
    }
    if (role !== null) {
      params = params.set('filter[role]', role);
    }
    if (filterKeyword !== null) {
      params = params.set('filter[search]', filterKeyword);
    }
    if (filterstatus !== null) {
      params = params.set('filter[status]', filterstatus);
    }
    return this.http.get<ResponseModel<OrderModel[]>>(
      'restaurants/' + this.restaurantID + '/branches/' + this.branchID + '/orders',
      { params }
    );
  }

  getOrderByID(id: string) {
    return this.http.get<ResponseModel<OrderModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/orders/${id}`
    );
  }

  editOrder(id: string, order: object) {
    return this.http.post<ResponseModel<OrderModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/orders/${id}?_method=PUT`,
      order
    );
  }

  deleteOder(id: string) {
    return this.http.delete(`restaurants/${this.restaurantID}/branches/${this.branchID}/orders/${id}`);
  }

  deleteSpecifiedOrderItem(orderId: string, orderItemId: string) {
    return this.http.delete(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/orders/${orderId}/order-items/${orderItemId}`
    );
  }

  addOrder(order: object) {
    return this.http.post<ResponseModel<OrderModel>>(
      'restaurants/' + this.restaurantID + '/branches/' + this.branchID + '/orders/add-order',
      order
    );
  }

  deleteManyItems(orderId: string, items: object) {
    return this.http.post<ResponseModel<OrderModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/orders/${orderId}/order-items/delete-items`,
      items
    );
  }

  addItemToSpecificOrder(orderId: string, items: object) {
    return this.http.post<ResponseModel<OrderModel>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/orders/${orderId}/order-items/add-order-item`,
      items
    );
  }

  getAllPayments(orderId: string) {
    return this.http.get<ResponseModel<PaymentModel[]>>(
      `restaurants/${this.restaurantID}/branches/${this.branchID}/orders/${orderId}/show-order-payment`
    );
  }
  sendUpdate(message: string) {
    this.update.next(message);
  }

  getUpdate() {
    return this.update.asObservable();
  }
}
