import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbComponentStatus } from '@nebular/theme';
import { PermissionsEnum } from '@shared/enum/permissions.enum';
import { DialogData } from '@shared/models/dialog-data.model';
import { HttpErrorModel } from '@shared/models/http-error.model';
import { SettingsModel } from '@shared/models/settings.model';
import { StripeSettingModel } from '@shared/models/stripeSetting.model';
import { LanguageService } from '@shared/services/language.service';
import { SettingService } from '@shared/services/setting/setting.service';
import { ToastService } from '@shared/services/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs';

@Component({
  selector: 'app-payment-gateway-settings-dialog',
  templateUrl: 'stripe-settings-dialog.component.html',
  styleUrls: ['stripe-settings-dialog.component.scss'],
})
export class StripeSettingsDialogComponent implements OnInit{

  permissionUpdate: string;
  permissionStore: string;
  submitted = false;
  stripeForm: UntypedFormGroup;
  settings: SettingsModel | null = null;
  stripeSettings: StripeSettingModel | null = null;
  httpError: HttpErrorModel | null = null;
  formData = new FormData();
  isAddStripeSettings: boolean = false;
  isEditStripeSettings: boolean = false;
  restaurantId: string;
  branchId: string;

  constructor(
    private fb: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private toastService: ToastService,
    private settingsService: SettingService,
    public language: LanguageService,
    public dialogRef: MatDialogRef<StripeSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.branchId = data.branchId;
    this.restaurantId = data.restaurantId;
    this.permissionUpdate = PermissionsEnum.UPDATE_BRANCH_SETTING;
    this.permissionStore = PermissionsEnum.STORE_BRANCH_SETTINGS;
    this.stripeForm = this.fb.group({
      STRIPE_KEY: [null, [Validators.required]],
      STRIPE_SECRET: [null, [Validators.required]],
      is_active: [null, [Validators.required]],
    });
  }

  get f() {
    return this.stripeForm.controls;
  }

  initStripeSettings() {
    this.f['STRIPE_SECRET'].setValue(this.stripeSettings.STRIPE_SECRET);
    this.f['STRIPE_KEY'].setValue(this.stripeSettings.STRIPE_KEY);
    this.f['is_active'].setValue(this.stripeSettings.is_active);
  }

  ngOnInit() {
    this.getStripeSettings();
  }

  submit() {
    this.submitted = true;
    if (this.stripeForm.invalid) {
      this.showNotification('Add Stripe Settings', 'Please check fields highlighted in red', 'info');
      return;
    }

    if (this.isEditStripeSettings) {
      this.updateStripeSetting();
      return;
    }
    this.createStripeSetting();
  }

  showNotification(title: string, message: string, type: NbComponentStatus) {
    this.toastService.showTranslatedToast(type, title, message);
  }

  private getStripeSettings() {
    this.spinnerService.show().then();
    this.settingsService
      .getSettingstoSpecificBranch(this.restaurantId, this.branchId)
      .pipe(first())
      .subscribe({
        next: value => {
          this.settings = value.data;
          if (!this.settings.stripe_config) {
            this.isAddStripeSettings = true;
            this.spinnerService.hide().then();
            return;
          } else {
            this.isEditStripeSettings = true;
          }
          this.stripeSettings = this.settings.stripe_config;
          this.initStripeSettings();
          this.spinnerService.hide().then();
        },
        error: (error: HttpErrorModel) => {
          this.spinnerService.hide().then();
          this.httpError = error;
        },
      });
  }

  private createStripeSetting() {
    this.spinnerService.show().then();
    this.formData.set('STRIPE_KEY', this.f['STRIPE_KEY'].value);
    this.formData.set('STRIPE_SECRET', this.f['STRIPE_SECRET'].value);
    this.formData.set('is_active', this.f['is_active'].value);
    const data = {
      stripe_config: Object.fromEntries(this.formData),
    }
    this.settingsService
      .addSettingstoSpecificBranch(this.restaurantId, this.branchId, data)
      .pipe(first())
      .subscribe({
        next: () => {
          this.spinnerService.hide().then();
          this.dialogRef.close(true);
          this.showNotification('Stripe settings', 'Stripe Settings created successfully', 'success');
        },
        error: error => {
          this.spinnerService.hide().then();
          this.httpError = error;
          this.showNotification(error.message, error.errors.toString(), 'danger');
        },
      });
  }

    private updateStripeSetting() {
      const stripeSettings = this.getChanges();
      const data = {
        stripe_config: Object.fromEntries(stripeSettings),
      }
      if (!stripeSettings.entries().next().value) {
        this.showNotification('Update Stripe Setting', 'No changes has been made', 'info');
        return;
      }
      this.spinnerService.show().then();
      this.settingsService
        .editSettingstoSpecificBranch(this.restaurantId, this.branchId, data)
        .pipe(first())
        .subscribe({
          next: () => {
            this.spinnerService.hide().then();
            this.dialogRef.close(true);
            this.showNotification('Stripe Setting', 'Stripe Setting updated successfully', 'success');
          },
          error: error => {
            this.spinnerService.hide().then();
            this.httpError = error;
            this.showNotification(error.message, error.errors.toString(), 'danger');
          },
        });
    }

  private getChanges() {
    if (this.f['STRIPE_SECRET'].value !== this.stripeSettings?.STRIPE_SECRET) {
      this.formData.set('STRIPE_SECRET', this.f['STRIPE_SECRET'].value);
    }
    if (this.f['STRIPE_KEY'].value !== this.stripeSettings?.STRIPE_KEY) {
      this.formData.set('STRIPE_KEY', this.f['STRIPE_KEY'].value);
    }
    if (this.f['is_active'].value !== this.stripeSettings?.is_active) {
      this.formData.set('is_active', this.f['is_active'].value);
    }
    return this.formData;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
