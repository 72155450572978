import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})

export class LanguageService {
    currentLAnguage:string ='';
    get isArabic() {
      return this.currentLAnguage === 'ar';
    }
    constructor(private translate: TranslateService) {
    this.currentLAnguage = this.translate.currentLang;
    }
}
