import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '@models/response.model';
import { UserModel } from '@models/user.model';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private http: HttpClient) {}

  getUserdetails() {
    return this.http.get<ResponseModel<UserModel>>(`auth/profile`);
  }

  editUser(userId: string, user: object) {
    return this.http.post<ResponseModel<UserModel>>(`auth/update/${userId}?_method=PUT`, user);
  }
}
