<nb-card class="page-wrapper">
  <nb-card-header class="page-header bg-white d-flex flex-column">
    <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
      <i class="bi bi-x-square-fill m-0"></i>
    </button>
    <p *ngIf="isAddStripeSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{ 'Add Stripe Settings' | translate }}
    </p>
    <p *ngIf="isEditStripeSettings" class="w-100 text-center tw-text-xl tw-font-medium main-color">
      {{ 'Update Stripe Settings' | translate }}
    </p>
  </nb-card-header>
  <nb-card-body class="page-body">
    <form
      action="javascript:"
      class="reset h-100"
      [formGroup]="stripeForm"
      [ngClass]="{ 'was-validated': submitted , 'rtl': language.isArabic}"
      class="table-wrapper p-4">
      <div class="h-100 d-flex flex-column justify-content-around">
        <div>

          
          <!-- STRIPE_KEY -->
          <div class="my-2">
            <label class="form-label">{{ 'Stripe Key' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['STRIPE_KEY'].errors }"
              formControlName="STRIPE_KEY"
              placeholder="{{ 'Please enter Stripe Key' | translate }}" />
          </div>

          <!-- STRIPE_SECRET -->
          <div class="my-2">
            <label class="form-label">{{ 'Stripe Secret' | translate }}</label>
            <input
              required
              class="form-control input-control"
              [ngClass]="{ 'is-invalid': submitted && f['STRIPE_SECRET'].errors }"
              formControlName="STRIPE_SECRET"
              placeholder="{{ 'Please enter Stripe Secret' | translate }}" />
          </div>

                  <!-- is_Active -->
        <div class="my-2">
          <label class="form-label">{{ 'Is Active' | translate }}</label>
          <select
            class="form-select input-control"
            aria-label="Default select example"
            formControlName="is_active"
            required
            [ngClass]="{ 'is-invalid': submitted && f['is_active'].errors }">
            <option [value]="0">
              {{ 'No' | translate }}
            </option>
            <option [value]="1">
              {{ 'Yes' | translate }}
            </option>
          </select>
        </div>
        

        </div>
        <!-- button -->
        <div>
          <button
            (click)="submit()"
            type="button"
            class="btn text-white tw-text-base tw-font-medium w-100 py-3 secondary-background-color tw-rounded-2xl">
            <p *ngIf="isAddStripeSettings && permissionStore">{{ 'Create Stripe Settings' | translate }}</p>
            <p *ngIf="isEditStripeSettings && permissionUpdate">{{ 'Update Stripe Settings' | translate }}</p>
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
