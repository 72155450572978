import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsModel } from '@shared/models/notifications.model';
import { PageData } from '@shared/models/pagedata.model';
import { NotificationsService } from '@shared/services/notifications.service';
import { StaffService } from '@shared/services/staff/staff.service';
import { map, shareReplay } from 'rxjs';

@Component({
  selector: 'app-notification-assigner-dialog',
  templateUrl: './notification-assigner-dialog.component.html',
  styleUrls: ['./notification-assigner-dialog.component.scss']
})
export class NotificationAssignerDialogComponent {
  pageData: PageData = { pageIndex: 0, pageSize: 100, totalRows: 0 };

  staff$ = this.staffService.getAll({ page: this.pageData.pageIndex + 1, perPage: this.pageData.pageSize, sort: null, filterKeyword: null, filterStatus: null, role: null })
  .pipe(
    map(res => res.data),
    shareReplay(1), 
  )

  chosenStaffId = null

  constructor(
    public dialogRef: MatDialogRef<NotificationAssignerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { notification: NotificationsModel },
    private staffService: StaffService,
    private notificationService: NotificationsService
  ) { }

  assign(){
    this.notificationService.assign_to_waiter({notificationId: this.data.notification.id, staffId: this.chosenStaffId}).subscribe(res => {
      this.dialogRef.close(res);
    });
  }


  closeDialog() {
    this.dialogRef.close();
  }
}
