export enum OrderStatusEnum {
  DRAFT = 1,
  PENDING = 2,
  ACCEPTED = 3,
  PREPARING = 4,
  READY = 5,
  FULLY_PAID  = 6,
  PENDING_USER_EDIT = 7,
  CANCELLED  = 8,
  PARTIALLY_PAID = 9,
}
