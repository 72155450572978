import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notifications-swither-style-button',
  templateUrl: './notifications-swither-style-button.component.html',
  styleUrls: ['./notifications-swither-style-button.component.css'],
})
export class NotificationsSwitherStyleButtonComponent {
  @Input() isGridView: boolean;
  @Output() toggleView = new EventEmitter<string>();

  onToggle(type: string) {
    localStorage.setItem('isGridView', type)
    this.toggleView.emit(type);
  }
}
