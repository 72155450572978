import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NbComponentStatus } from '@nebular/theme';
import { OrderStatusEnum } from '@shared/enum/order-status.enum';
import { PaymentStatusEnum } from '@shared/enum/payment_status.enum';
import { NotificationsModel } from '@shared/models/notifications.model';
import { PaymentModel } from '@shared/models/payment.model';
import { LanguageService } from '@shared/services/language.service';
import { OrderService } from '@shared/services/order/order.service';
import { PaymentService } from '@shared/services/payment/payment.service';
import { TableService } from '@shared/services/table/table.service';
import { ToastService } from '@shared/services/toast.service';
import { MethodsUtils } from '@shared/utils/methods.utils';
import { first } from 'rxjs';

@Component({
  selector: 'app-change-payment-cash-status-to-paid-dialog',
  templateUrl: './change-payment-cash-status-to-paid-dialog.component.html',
  styleUrls: ['./change-payment-cash-status-to-paid-dialog.component.scss'],
})
export class ChangePaymentCashStatusToPaidDialogComponent implements OnInit{
  orderStatusEnum;
  paymentId: string = '';
  orderStatus: string = '';
  payment: PaymentModel;
  constructor(
    public dialogRef: MatDialogRef<ChangePaymentCashStatusToPaidDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { notification: NotificationsModel; paymentId: string },
    private methodUtils: MethodsUtils,
    private orderService: OrderService,
    private paymentService: PaymentService,
    public language: LanguageService,
    private tableService: TableService,
    private toastService: ToastService
  ) {
    this.orderStatusEnum = this.methodUtils.getStatuses(OrderStatusEnum);
    this.paymentId = data.paymentId;
  }

  ngOnInit() {
   this.paymentService.getPaymentByID(this.paymentId)
   .pipe(first())
   .subscribe({
     next: value => {
       this.payment = value.data;
     }
   });
  }

  changePaymentStatusToPaid() {
    const data = {
      status: PaymentStatusEnum.PAID as number,
     }
    this.paymentService
      .editPayment(this.paymentId, data)
      .pipe(first())
      .subscribe({
        next: () => {
            this.tableService.updateStatusTableToLocked(this.payment.table_id).subscribe({
              next: () => {
                this.showNotification('Payment', 'Payment status update successfully', 'success');
              },
            });
          this.dialogRef.close(true);
        },
        error: error => {
          this.showNotification(error.message, error.errors.toString(), 'danger');
        },
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  showNotification(title: string, message: string, type: NbComponentStatus) {
    this.toastService.showTranslatedToast(type, title, message);
  }
}
