<nb-card class="mb-0 p-4 pt-2 pb-0">
  <nb-card-body class="p-0">
    <button (click)="closeDialog()" class="btn border-0 m-0 p-0 w-100 text-end mt-1">
      <i class="bi bi-x-square-fill m-0"></i>
    </button>
    <h5 class="my-3">{{ 'Payment Details' | translate }}</h5>
    <div>
      <div class="row pb-1">
        <div class="col-6">
          <p class="main-color tw-font-medium tw-text-base">{{ payment.customer_phone }}</p>
        </div>
        <div class="col-6">
          <p
            class="tw-text-lg tw-font-semibold main-color"
            [ngClass]="{ 'text-start': language.isArabic, 'text-end': !language.isArabic }">
            {{ payment.amount | dividePrice }}
          </p>
        </div>
      </div>

      <div class="row pb-1">
        <div class="col-6">
          <p class="secondary-color tw-text-base">{{ 'Tip Amount' | translate }}</p>
        </div>
        <div class="col-6">
          <p
            class="tw-text-base gray-color"
            [ngClass]="{ 'text-start': language.isArabic, 'text-end': !language.isArabic }">
            {{ payment.tip_amount | dividePrice }} {{ 'AED' | translate }}
          </p>
        </div>
      </div>

      <div class="row pb-1">
        <div class="col-6">
          <p class="secondary-color tw-text-base">{{ 'Status' | translate }}</p>
        </div>
        <div class="col-6">
          <p
            class="tw-text-base strong-orange-color"
            [ngClass]="{ 'text-start': language.isArabic, 'text-end': !language.isArabic }">
            {{ payment.status.value | formatType }}
          </p>
        </div>
      </div>

      <div class="w-100 text-center">
        <button class="btn tw-rounded-lg tw-bg-primary my-3 tw-text-white" (click)="changePaymentStatusToPaid()">
            {{ 'Mark is paid' | translate }}
          </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
